import React, { useEffect, useState } from 'react';
import { MIN_YEAR, MAX_YEAR } from '../includes/constants';

const SearchCard = ({ searchParams, setSearchParams, handleSearch }) => {
  let {
    fromYear,
    untilYear,
    searchTerm,
    searchType,
    wholeWordOnly,
    flags,
    contextLeft,
    contextRight,
  } = searchParams;

  const [searchPlaceHolder, setSearchPlaceHolder] = useState(
    'Enter search string'
  );

  useEffect(() => {
    document.getElementById('fromYearSelect').value = fromYear;
    document.getElementById('untilYearSelect').value = untilYear;
  }, [fromYear, untilYear]);

  const Options = (min, max) => {
    const iterations = max - min + 1;
    const values = [...Array(iterations).keys()].map(key => key + min);
    return values.map(value => <option key={value}>{value}</option>);
  };

  const handleParamsChange = e => {
    switch (e.target.id) {
      case 'fromYearSelect': {
        const myUntilYearSelect = document.getElementById('untilYearSelect');
        if (e.target.selectedIndex > myUntilYearSelect.selectedIndex) {
          myUntilYearSelect.selectedIndex = e.target.selectedIndex;
        }
        setSearchParams({
          ...searchParams,
          fromYear: parseInt(e.target.value),
          untilYear: parseInt(myUntilYearSelect.value),
        });
        break;
      }
      case 'untilYearSelect': {
        const myFromYearSelect = document.getElementById('fromYearSelect');
        if (e.target.selectedIndex < myFromYearSelect.selectedIndex) {
          myFromYearSelect.selectedIndex = e.target.selectedIndex;
        }
        setSearchParams({
          ...searchParams,
          untilYear: parseInt(e.target.value),
          fromYear: parseInt(myFromYearSelect.value),
        });
        break;
      }
      case 'searchInput': {
        setSearchParams({
          ...searchParams,
          searchTerm: e.target.value,
        });

        break;
      }
      case 'stringOption': {
        setSearchPlaceHolder('Enter search string');
        setSearchParams({
          ...searchParams,
          searchType: 'string',
        });
        break;
      }
      case 'regexOption': {
        setSearchPlaceHolder('Enter regular expression');
        setSearchParams({
          ...searchParams,
          searchType: 'regex',
        });
        break;
      }
      case 'wholeWordOnlyCheckbox': {
        setSearchParams({ ...searchParams, wholeWordOnly: e.target.checked });
        break;
      }
      case 'caseInsensitiveCheckbox': {
        let myFlags = flags;
        if (e.target.checked) {
          if (!myFlags.includes('i')) {
            myFlags += 'i';
          }
        } else {
          if (myFlags.includes('i')) {
            myFlags = myFlags.split('i').join('');
          }
        }
        setSearchParams({ ...searchParams, flags: myFlags });
        break;
      }
      case 'radiusSelect': {
        setSearchParams({ ...searchParams, radius: parseInt(e.target.value) });
        break;
      }
      case 'leftContextSelect': {
        setSearchParams({
          ...searchParams,
          contextLeft: parseInt(e.target.value),
        });
        break;
      }
      case 'rightContextSelect': {
        setSearchParams({
          ...searchParams,
          contextRight: parseInt(e.target.value),
        });
        break;
      }
      default:
        break;
    }
  };

  const myHandleSearch = e => {
    // Check if regex is well formed

    let myInput = document.getElementById('searchInput');
    if (searchParams.searchTerm === '') {
      myInput.classList.add('is-invalid');
      setTimeout(() => {
        myInput.classList.remove('is-invalid');
      }, 5000);
      e.preventDefault();
      myInput.focus();
      return;
    }
    if (searchParams.searchType === 'regex') {
      try {
        let regex = new RegExp(searchParams.searchTerm, searchParams.flags);
      } catch {
        myInput.classList.add('is-invalid');
        setTimeout(() => {
          myInput.classList.remove('is-invalid');
        }, 5000);
        e.preventDefault();
        myInput.focus();
        return;
      }
    }

    handleSearch(true);
  };

  return (
    <div className='col mx-auto'>
      <form onSubmit={e => myHandleSearch(e)}>
        <div className='card outmost-card text-white bg-primary my-3 mx-auto'>
          <div className='card-header'>
            <div className='d-flex flex-row justify-content-center align-items-center'>
              <i className='fas fa-search'></i> Search
              <div>
                <span class='badge rounded-pill bg-warning ml-3'>
                  Beta-Version - use with caution
                </span>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className='form-row align-items-center mb-1'>
              <div className='col-6'>
                <label
                  htmlFor='fromYearSelect'
                  className='col-form-label col-form-label-sm'
                >
                  From
                </label>
                <select
                  className='form-control form-control-sm mb-3'
                  id='fromYearSelect'
                  onChange={handleParamsChange}
                >
                  {Options(MIN_YEAR, MAX_YEAR)}
                </select>
              </div>
              <div className='col-6'>
                <label
                  htmlFor='untilYearSelect'
                  className='col-form-label col-form-label-sm'
                >
                  Until
                </label>
                <select
                  className='form-control form-control-sm mb-3'
                  id='untilYearSelect'
                  onChange={handleParamsChange}
                >
                  {Options(MIN_YEAR, MAX_YEAR)}
                </select>
              </div>
            </div>
            <div className='row align-items-end'>
              <div className='card border-secondary col-12'>
                <div className='form-group'>
                  <label
                    htmlFor='searchInput'
                    className='col-form-label col-form-label-sm'
                  >
                    Search for
                  </label>
                  <input
                    type='text'
                    id='searchInput'
                    className='form-control form-control-sm'
                    placeholder={searchPlaceHolder}
                    autoCorrect='off'
                    autoCapitalize='off'
                    spellCheck='false'
                    value={searchTerm}
                    onChange={handleParamsChange}
                  />
                  {searchParams.searchType === 'regex' ? (
                    <div className='invalid-feedback'>
                      Please enter Regex without forward slashes and without
                      flags (e.g. "i").
                    </div>
                  ) : (
                    <div className='invalid-feedback'>
                      Please insert a search-term.
                    </div>
                  )}
                </div>

                <div className='form-row mb-3'>
                  <div className='col'>
                    <div className='card text-white bg-primary mb-1'>
                      <div className='card-header'>Options</div>
                      <div className='card-body'>
                        <fieldset className='form-group text-left'>
                          <div className='form-check form-check-inline'>
                            <label className='form-check-label'>
                              <input
                                type='radio'
                                className='form-check-input'
                                name='stringRegexOptions'
                                id='stringOption'
                                value='string'
                                checked={searchType === 'string' ? true : false}
                                onChange={handleParamsChange}
                              />
                              String
                            </label>
                          </div>
                          <div className='form-check form-check-inline'>
                            <label className='form-check-label'>
                              <input
                                type='radio'
                                className='form-check-input'
                                name='stringRegexOptions'
                                id='regexOption'
                                value='regex'
                                checked={searchType === 'regex' ? true : false}
                                onChange={handleParamsChange}
                              />
                              Regex
                            </label>
                          </div>
                        </fieldset>
                        <div className='form-check text-left'>
                          <input
                            className='form-check-input'
                            value=''
                            type='checkbox'
                            id='caseInsensitiveCheckbox'
                            checked={flags.includes('i') ? true : false}
                            // disabled={searchType === 'regex'}
                            onChange={handleParamsChange}
                          />
                          <label
                            className='form-check-label text-nowrap'
                            htmlFor='caseInsensitiveCheckbox'
                            onClick={handleParamsChange}
                          >
                            Case Insensitive
                          </label>
                        </div>
                        <div className='form-check text-left'>
                          <input
                            className='form-check-input'
                            value=''
                            type='checkbox'
                            id='wholeWordOnlyCheckbox'
                            checked={wholeWordOnly}
                            disabled={searchType === 'regex'}
                            onChange={handleParamsChange}
                          />
                          <label
                            className='form-check-label text-nowrap'
                            htmlFor='wholeWordOnlyCheckbox'
                            onClick={handleParamsChange}
                          >
                            Whole Word
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col context-col'>
                    <div className='card text-white bg-primary'>
                      <div className='card-header'>Context</div>
                      <div className='card-body'>
                        <div className='form-row'>
                          <div className='col-6'>
                            <div>
                              <label
                                htmlFor='leftContextSelect'
                                className='form-check-label'
                              >
                                Left
                              </label>
                              <select
                                className='form-control form-control-sm'
                                value={contextLeft}
                                id='leftContextSelect'
                                onChange={handleParamsChange}
                              >
                                {Options(0, 128)}
                              </select>
                            </div>
                          </div>
                          <div className='col-6'>
                            <div>
                              <label
                                htmlFor='rightContextSelect'
                                className='form-check-label'
                              >
                                Right
                              </label>
                              <select
                                className='form-control form-control-sm'
                                value={contextRight}
                                id='rightContextSelect'
                                onChange={handleParamsChange}
                              >
                                {Options(0, 128)}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex align-items-center pb-3 justify-content-center'>
          <div className='col pb-3'>
            <button
              type='submit'
              className='btn btn-primary mb-3'
              // onClick={e => myHandleSearch(e)}
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchCard;
