const ManIcon = props => {
  return (
    <svg
      id='Man'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 42 100'
      fill='none'
      {...props}
    >
      <g>
        <circle cx='21' cy='14' r='11.5' strokeWidth='5' />
      </g>
      <g>
        <path
          d='m12.5,97.5v-29H2.5v-29.5c0-3.67,2.67-6.77,6.21-7.38,3.62,2.54,7.85,3.88,12.29,3.88s8.66-1.34,12.29-3.88c3.54.61,6.21,3.7,6.21,7.38v29.5h-10v29H12.5Z'
          strokeWidth='5'
        />
      </g>
    </svg>
  );
};

export default ManIcon;
