import React from 'react';
import TagRow from './TagRow';
import { PAGE_SIZE } from '../includes/constants';

const TagsSlider = ({
  docsWithHits,
  startTooltip,
  endTooltip,
  screenWidth,
  page,
  tagsExpandedByUser,
  setTagsExpandedByUser,
}) => {
  console.log(docsWithHits.length);
  return (
    <div className={`tags-slider${tagsExpandedByUser ? ' open' : ''}`}>
      {docsWithHits.map((hit, index) => {
        return (
          <TagRow
            row={hit}
            key={index}
            index={index + 1 + page * PAGE_SIZE}
            startTooltip={startTooltip}
            endTooltip={endTooltip}
          />
        );
      })}
    </div>
  );
};

export default TagsSlider;
