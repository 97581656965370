import React from 'react';
import { useState } from 'react';
import buildJSON from '../includes/buildJSON';
import UtteranceServices from '../services/UtteranceServices';
import SaveSuccess from './SaveSuccess';
import Waiting from './Waiting';
import FileListElement from './FileListElement';
// import Tooltip from 'react-tooltip-lite';

function FileHandling() {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [allUploaded, setAllUploaded] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uCounter, setUCounter] = useState(0);
  const [replace, setReplace] = useState(true);
  const [progress, setProgress] = useState(0);

  const removeFileFromList = index => {
    let myFileList = Array.from(fileList);
    myFileList.splice(index, 1);
    setFileList(myFileList);
  };

  const setFilesToNotUploaded = file => {
    return {
      file: file,
      uploaded: false,
    };
  };

  const selectFiles = async e => {
    const input = document.getElementById('file-selector');
    let myFileList = Array.from(input.files);
    let myCompleteFileList = myFileList.map(setFilesToNotUploaded);
    setFileList(myCompleteFileList);
    setAllUploaded(false);
  };

  const readFile = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsText(file);
    });
  };

  const handleOnClickOpen = e => {
    const input = document.getElementById('file-selector');
    input.value = null;
    input.click();
    e.target.blur();
  };

  const processFiles = async e => {
    const chunkSize = 5;
    e.target.blur();

    let myUCounter = 0;
    // let myFileList = JSON.parse(JSON.stringify(fileList));
    // console.log(myFileList);
    // return;
    if (fileList.length !== 0) {
      setWaiting(true);
      for (let i = 0; i < fileList.length; i++) {
        let file = fileList[i].file;
        setProgress(i);
        const parser = new DOMParser();
        const myData = await readFile(file);
        let xmlDoc;

        try {
          setError(false);
          xmlDoc = parser.parseFromString(myData, 'text/xml');
          const jsonData = buildJSON(xmlDoc, file.name);
          myUCounter += jsonData.length;
          let myCurrentTrial;
          let myLastTrial;

          // Delete utterances to be replaced
          if (replace) {
            for (let i = 0; i < jsonData.length; i++) {
              myCurrentTrial = jsonData[i].trial;
              // console.log(myCurrentTrial, myLastTrial);
              if (myCurrentTrial !== myLastTrial) {
                await UtteranceServices.deleteTrial({
                  trial: myCurrentTrial,
                });
                myLastTrial = myCurrentTrial;
              }
            }
          }

          // console.log(jsonData);

          // Store utterances to db
          for (let i = 0; i < jsonData.length; i = i + chunkSize) {
            await UtteranceServices.createMany(
              jsonData.slice(i, i + chunkSize)
            );
          }
          fileList[i].uploaded = true;
        } catch (err) {
          console.log(err);
          continue;
        }
      }
      let myAllUploaded = true;
      for (const file of fileList) {
        if (file.uploaded === false) {
          myAllUploaded = false;
          break;
        }
      }
      setAllUploaded(myAllUploaded);
      setWaiting(false);
      setProgress(0);
      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000);
      setUCounter(myUCounter);
    }
  };

  // const overwriteText = (
  //   <div className='tt-mediumwide'>
  //     If checked, entries with the same trial-id will be deleted before adding
  //     the new entries.
  //     <br /> To improve performance, it is recommended to uncheck 'Overwrite
  //     entries' when loading a database from scratch.
  //   </div>
  // );

  return (
    <div>
      {success && <SaveSuccess number={uCounter} />}

      <div className='container mt-3 mw-100'>
        <div className='row'>
          <div className='col mx-auto'>
            <div className='card outmost-card text-white bg-primary mb-3 mx-auto'>
              <div className='card-header'>
                <i className='fas fa-cloud-upload-alt'></i> Upload Files
              </div>
              <div className='card-body'>
                <input
                  id='file-selector'
                  accept='.xml'
                  type='file'
                  className='btn btn-primary mb-3 mt-3'
                  onChange={e => selectFiles(e)}
                  style={{ display: 'none' }}
                  multiple
                />
                <div className='row justify-content-between align-items-start'>
                  <div className='col'>
                    <button
                      className='btn btn-primary'
                      onClick={handleOnClickOpen}
                    >
                      Select files
                    </button>
                  </div>
                  <div className='col'>
                    <fieldset
                      className='form-group text-left'
                      // disabled={fileList.length === 0 && 'disabled'}
                    >
                      <button
                        className={`${
                          (fileList.length === 0 || allUploaded) && 'disabled'
                        } btn btn-primary obc-btn`}
                        // className='btn btn-primary obc-btn'
                        onClick={processFiles}
                      >
                        {waiting ? (
                          <div>
                            <Waiting />
                            <span>{` ${progress}/${fileList.length}`}</span>
                          </div>
                        ) : (
                          'Store files in database'
                        )}
                      </button>
                      {/* <Tooltip content={overwriteText} distance={3}> */}
                      <div className='form-check mt-1'>
                        <label className='form-check-label'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            checked={replace}
                            onChange={() => setReplace(!replace)}
                          />
                          Overwrite entries
                        </label>
                      </div>
                      {/* </Tooltip> */}
                    </fieldset>
                  </div>
                </div>

                {fileList.length !== 0 && (
                  <div id='fileArea'>
                    <div className='form-group'>
                      <label htmlFor='exampleTextarea'>
                        Selected files: {fileList.length}
                      </label>
                      <ul className='list-group'>
                        {fileList.map((file, index) => {
                          return (
                            <FileListElement
                              key={index}
                              file={file}
                              index={index}
                              deleteFile={removeFileFromList}
                            />
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {error && (
        <div className='row'>
          <div className='col-sm-8  mx-auto'>
            <div className='alert alert-dismissible alert-primary'>
              <button type='button' className='close' data-dismiss='alert'>
                &times;
              </button>
              <h4 className='alert-heading'>Could not parse XML-file.</h4>
              <ul className='list-group '>
                <li className='list-group-item d-flex justify-content-between align-items-left'>
                  All ampersands "&" must be escaped as "{'&amp;'}"
                </li>
                <li className='list-group-item d-flex justify-content-between align-items-left'>
                  Attributes in a node must be unique (e.g. no two
                  "wc"-attributes in one u-Tag)
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FileHandling;
