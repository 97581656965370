import React, { useState, useContext } from 'react';
import UtteranceServices from '../services/UtteranceServices';
import UserContext from '../context/UserContext';
import AlertCard from './AlertCard';

const initialUser = {
  name: '',
  role: 'Data Handler',
  email: '',
};

const NewUserListElement = ({ reloadUsers }) => {
  const [newUser, setNewUser] = useState(initialUser);

  const [rolledOut, setRolledOut] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [msg, setMsg] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const { userData } = useContext(UserContext);

  const createUser = async e => {
    e.currentTarget.blur();

    try {
      let myRes = await UtteranceServices.createUser({
        user: { name: newUser.name, role: newUser.role, email: newUser.email },
        adminToken: userData.token,
      });
      if (myRes.status === 200) {
        setRolledOut(false);
        setShowSuccess(true);
        setNewUser(initialUser);
        setMsg(myRes.data.msg);
        setTimeout(() => {
          setShowSuccess(false);
          setMsg(undefined);
          reloadUsers();
        }, 3000);
      }
    } catch (error) {
      setShowAlert(true);
      error.response.data.msg && setMsg(error.response.data.msg);
      setTimeout(() => {
        setShowAlert(false);
        setMsg(undefined);
      }, 3000);
      console.log(error);
    }
  };

  const cancel = e => {
    setRolledOut(false);
  };

  const changeName = e => {
    setNewUser({ ...newUser, name: e.target.value });
  };

  const changeEmail = e => {
    setNewUser({ ...newUser, email: e.target.value });
  };

  const changeRole = e => {
    setNewUser({ ...newUser, role: e.target.value });
  };

  const rolledOutJSX = (
    <div>
      <div className='row d-flex flex-nowrap'>
        <div className='col'>
          <label htmlFor='inputUserName' className='mb-0'>
            User Name
          </label>
          <input
            id='inputUserName'
            className='form-control form-control-sm mt-0 mb-3'
            type='text'
            value={newUser.name}
            onChange={changeName}
            autoComplete='off'
            spellCheck='false'
          />
        </div>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='selectUserRole' className='form-label-sm mb-0'>
              Role
            </label>
            <select
              className='form-control form-control-sm'
              id='selectUserRole'
              value={newUser.role}
              onChange={changeRole}
            >
              <option>Administrator</option>
              <option>Data Handler</option>
            </select>
          </div>
        </div>
      </div>

      <label htmlFor='inputEmail' className='mb-0'>
        Email
      </label>
      <input
        id='inputEmail'
        className='form-control form-control-sm mt-0 mb-3'
        type='text'
        value={newUser.email}
        onChange={changeEmail}
        autoComplete='off'
        spellCheck='false'
      />

      <div className='d-flex justify-content-between'>
        <button className='btn btn-primary' onClick={cancel}>
          <i className='far fa-times-circle pointer-cursor mr-2'></i>
          Cancel
        </button>

        <button className='btn btn-primary' onClick={createUser}>
          <i className='far fa-save pointer-cursor mr-2'></i>Create User and
          mail Credentials
        </button>
      </div>
    </div>
  );

  const rolledUpJSX = (
    <div className='row justify-content-center'>
      <div
        className='btn btn-primary pointer-cursor'
        onClick={() => setRolledOut(true)}
      >
        <i className='far fa-plus-square'></i> Create new User
      </div>
    </div>
  );

  return (
    <div className='card mb-3'>
      {rolledOut && (
        <div className='card-header text-center'>Create new User</div>
      )}

      <div className='card-body text-left'>
        {showSuccess && <AlertCard type='success' msg={msg} />}
        {showAlert && <AlertCard type='danger' msg={msg} />}
        {rolledOut ? rolledOutJSX : rolledUpJSX}
      </div>
    </div>
  );
};

export default NewUserListElement;
