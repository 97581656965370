const WomanIcon = props => {
  return (
    <svg
      id='Woman'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 46.19 100'
      fill='none'
      {...props}
    >
      <circle cx='23.1' cy='14' r='11.5' strokeWidth='5' />

      <path
        d='m14.6,97.5v-26H6.01c-1.11,0-2.13-.51-2.79-1.39-.67-.88-.88-2-.58-3.06l7.42-26.59c1.14-4.1,4.91-6.96,9.17-6.96h7.75c4.25,0,8.03,2.86,9.17,6.96l7.42,26.59c.3,1.07.08,2.18-.58,3.06-.67.88-1.69,1.39-2.8,1.39h-8.58v26H14.6Z'
        strokeWidth='5'
      />
    </svg>
  );
};

export default WomanIcon;
