import React from 'react';
import { URL_HISCO } from '../includes/constants';

const HiscoLabel = ({ hiscoLabel, hiscoCode, startTooltip, endTooltip }) => {
  const url = `${URL_HISCO}${hiscoCode}`;

  // widthHeight in rem
  const widthHeight = {
    width: 24,
    height: 7,
  };

  const hiscoLabelText = (
    <div className='v-align'>
      <strong>{hiscoLabel}</strong> <br />
      hiscoCode: <strong>{hiscoCode}</strong>
      <br />
      <br />
      <a target='_blank' rel='noopener noreferrer' href={url}>
        Click to see occupation description on HISCO website
      </a>
    </div>
  );

  return (
    <a
      target='_blank'
      rel='noopener noreferrer'
      href={url}
      onPointerEnter={e => startTooltip(hiscoLabelText, e, widthHeight)}
      onPointerLeave={e => endTooltip(e)}
    >
      <span className={`badge badge-pill badge-warning hisco-badge obc-tag`}>
        <span className='hisco-text'>{hiscoLabel}</span>
      </span>
    </a>
  );
};

export default HiscoLabel;
