import React, { useState } from 'react';
import Table from './Table';
import SearchCard from './SearchCard';
import {
  MIN_YEAR,
  MAX_YEAR,
  OBC_VERSION,
  APP_VERSION,
} from '../includes/constants';

function Search({ firstLoad, setFirstLoad, screenWidth }) {
  const minYear = MIN_YEAR;
  const maxYear = MAX_YEAR;

  const [showTable, setShowTable] = useState(false);

  const [searchParams, setSearchParams] = useState({
    page: 0,
    fromYear: minYear,
    untilYear: maxYear,
    // searchFor: '',
    searchTerm: '',
    searchType: 'string',
    wholeWordOnly: false,
    flags: 'i',
    includeActivity: false,
    contextLeft: 64,
    contextRight: 64,
    obcVersion: OBC_VERSION,
    appVersion: APP_VERSION,
  });

  const handleSearch = show => {
    setShowTable(show);
    setFirstLoad(true);
  };

  return (
    <div className='container mw-100'>
      <div className='row'>
        {showTable ? (
          <Table
            searchParams={searchParams}
            handleSearch={handleSearch}
            firstLoad={firstLoad}
            setFirstLoad={setFirstLoad}
            screenWidth={screenWidth}
          />
        ) : (
          <SearchCard
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            handleSearch={handleSearch}
            screenWidth={screenWidth}
          />
        )}
      </div>
    </div>
  );
}

export default Search;
