import React, { useState, useEffect, Fragment } from 'react';
import { BREAKPOINT_MD } from '../includes/constants';
import ArrowFromLineIcon from './icons/ArrowFromLineIcon';

const TableHeader = ({
  screenWidth,
  tagsExpandedByUser,
  setTagsExpandedByUser,
}) => {
  // const [isSticky, setIsSticky] = useState(false);
  const [placeHolderHeight, setPlaceHolderHeight] = useState(0);

  // Add event listeners for resize and scroll
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  useEffect(() => {
    const headerHeight = document.getElementById('myHeader').clientHeight;
    setPlaceHolderHeight(headerHeight);
  }, []);

  // switch between sticky and not sticky mode
  const handleScroll = () => {
    const header = document.getElementById('myHeader');
    const tableBtnsHeight =
      document.getElementById('table-buttons').clientHeight;
    const obcTableMarginBottom = 15;
    const navBarHeight =
      document.getElementById('obcNavbarWrapper').clientHeight;
    let stickyHeight = tableBtnsHeight + obcTableMarginBottom + navBarHeight;

    if (window.scrollY > stickyHeight) {
      header.classList.add('sticky');
      // setIsSticky(true);
    } else {
      header.classList.remove('sticky');
      // setIsSticky(false);
    }
  };

  const toggleExpanded = () => {
    let newValue = !tagsExpandedByUser;
    setTagsExpandedByUser(newValue);
  };

  return (
    <Fragment>
      <div className='row px-0' id='myHeader'>
        <div className='col-number px-0'>
          <div className='table-header btn-block'>No</div>
        </div>
        <div className='col text-left  px-0' id='matchesColumn'>
          <div id='matchesHeader' className='table-header btn-block'>
            Matches
          </div>
        </div>
        <div
          className={`text-left tag-col ${
            screenWidth < BREAKPOINT_MD ? 'narrow-tag-menu-col' : ''
          }`}
          onClick={toggleExpanded}
        >
          <div
            className={`table-header btn-block ${
              tagsExpandedByUser ? ' open' : ''
            }`}
          >
            Tags
            {screenWidth < BREAKPOINT_MD && (
              <ArrowFromLineIcon
                width='14px'
                id='tag-menu-arrow'
                className={`${tagsExpandedByUser ? ' rotated' : ''}`}
              />
            )}
            {/* {screenWidth < BREAKPOINT_MD && (
              <span
                id='tag-menu-arrow'
                className={`${tagsExpandedByUser ? ' rotated' : ''}`}
              >
                <i className='fas fa-arrow-circle-left fa-lg'></i>
              </span>
            )} */}
          </div>
        </div>
      </div>
      <div id='placeHolder' style={{ height: placeHolderHeight + 'px' }}></div>
    </Fragment>
  );
};

export default TableHeader;
