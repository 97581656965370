import React, { useState } from 'react';
import Waiting from './Waiting';
import { BREAKPOINT_LG, BREAKPOINT_SM } from '../includes/constants';
import LastIcon from './icons/LastIcon';
import FirstIcon from './icons/FirstIcon';
import RewIcon from './icons/RewIcon';
import BackIcon from './icons/BackIcon';
import ForwardIcon from './icons/ForwardIcon';
import FFIcon from './icons/FFIcon';
// import Tooltip from 'react-tooltip-lite';

const Pagination = ({ page, maxPages, getPage, loading, screenWidth }) => {
  // useEffect(() => {
  //   let myMin = 0;
  //   let myMax = 5;
  //   if (page < 3) {
  //     myMin = 0;
  //     myMax = 5;
  //   } else if (page > maxPages - 3) {
  //     myMin = maxPages - 4;
  //     myMax = maxPages + 1;
  //   } else {
  //     myMin = page - 2;
  //     myMax = page + 3;
  //   }
  //   if (myMin < 0) {
  //     myMin = 0;
  //   }
  //   if (myMax > maxPages + 1) {
  //     myMax = maxPages + 1;
  //   }
  //   console.log(maxPages);
  // }, [page, maxPages]);

  // useEffect(() => {
  // let allListItems = document.querySelectorAll('ul.pagination > li');
  // if (loading) {
  //   allListItems.forEach(item => item.classList.add('disabled'));
  // } else {
  //   allListItems.forEach(item => item.classList.remove('disabled'));
  // }
  // }, [loading]);

  const [breakToSmallBtns] = useState(432);

  // handle direct page no input by user
  const handleKeyUp = e => {
    if (e.key !== 'Enter') return;
    let newPage = parseInt(e.target.value);
    if (isNaN(newPage)) return;
    handleOnClick(newPage - 1);
  };

  // handle page change per click
  const handleOnClick = newPage => {
    if (loading) return;
    newPage < 0 && (newPage = 0);
    newPage > maxPages - 1 && (newPage = maxPages - 1);
    if (newPage !== page) {
      getPage(newPage);
    }
  };

  const handleFocus = e => {
    e.target.placeholder = '';
  };

  const handleBlur = e => {
    e.target.placeholder = `Page ${page + 1} / ${maxPages}`;
  };

  const PageInputJsx = () => {
    return (
      <div>
        <input
          type='text'
          id='pageInput'
          className='page-link page-input text-center'
          placeholder={
            maxPages === 0 ? 'No matches' : `Page ${page + 1} / ${maxPages}`
          }
          style={
            screenWidth < BREAKPOINT_SM
              ? {
                  maxWidth: 180 + 'px',
                  maxHeight: 22 + 'px',
                  minHeight: 'unset',
                }
              : {}
          }
          autoCorrect='off'
          autoCapitalize='off'
          spellCheck='false'
          onKeyUp={handleKeyUp}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
    );
  };

  return (
    <div
      className={`alert alert-primary text-center pagination-card ${
        screenWidth < BREAKPOINT_LG ? 'w-100 mb-1' : ''
      }`}
    >
      <div
        className={`row align-items-center pagination-items flex-nowrap ${
          screenWidth < BREAKPOINT_LG ? 'justify-content-center' : ''
        }`}
      >
        <ul
          className={`pagination text-right ${
            screenWidth < BREAKPOINT_SM && 'pagination-sm'
          }`}
        >
          <li className='page-item'>
            <div className='page-link' onClick={() => handleOnClick(0)}>
              <div className='page-link-icon-wrapper'>
                <FirstIcon height='13px' />
              </div>
            </div>
          </li>

          {screenWidth >= breakToSmallBtns && (
            <li className='page-item'>
              <div
                className='page-link'
                onClick={() => handleOnClick(page - 10)}
              >
                <div className='page-link-icon-wrapper'>
                  <RewIcon height='13px' />
                </div>
              </div>
            </li>
          )}
          <li className='page-item'>
            <div className='page-link' onClick={() => handleOnClick(page - 1)}>
              <div className='page-link-icon-wrapper'>
                <BackIcon height='13px' />
              </div>
            </div>
          </li>
        </ul>
        <ul
          className={`pagination text-right ${
            screenWidth < BREAKPOINT_SM ? 'pagination-sm mx-1' : 'mx-3'
          }`}
        >
          <li className='page-item active'>
            {/* {loading ? (
              <div
                className='page-input text-center'
                style={
                  screenWidth < BREAKPOINT_SM ? { maxWidth: 180 + 'px' } : {}
                }
              >
               
                Loading...
              </div>
            ) : (
              <PageInputJsx />
            )} */}
            <PageInputJsx />
          </li>
        </ul>
        <ul
          className={`pagination text-right ${
            screenWidth < BREAKPOINT_SM && 'pagination-sm'
          }`}
        >
          <li className='page-item'>
            <div className='page-link' onClick={() => handleOnClick(page + 1)}>
              <div className='page-link-icon-wrapper'>
                <ForwardIcon height='13px' />
              </div>
            </div>
          </li>
          {screenWidth >= breakToSmallBtns && (
            <li className='page-item'>
              <div
                className='page-link'
                onClick={() => handleOnClick(page + 10)}
              >
                <div className='page-link-icon-wrapper'>
                  <FFIcon height='13px' />
                </div>
              </div>
            </li>
          )}
          <li className='page-item'>
            <div className='page-link' onClick={() => handleOnClick(maxPages)}>
              <div className='page-link-icon-wrapper'>
                <LastIcon height='13px' />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Pagination;
