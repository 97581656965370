import React from 'react';
import Waiting from './Waiting';
import { BREAKPOINT_LG, BREAKPOINT_SM } from '../includes/constants';

const SearchSuccess = ({
  totalHitsCount,
  noFrom,
  noTo,
  loading,
  screenWidth,
}) => {
  let myNoTo = noTo;
  if (noTo > totalHitsCount) {
    myNoTo = totalHitsCount;
  }

  return (
    <div
      className={`alert text-center search-card ${
        loading
          ? 'alert-primary'
          : totalHitsCount === 0
          ? 'alert-danger'
          : 'alert-primary'
      } ${screenWidth <= BREAKPOINT_LG ? 'mb-1' : ''} ${
        screenWidth < BREAKPOINT_SM ? 'w-100' : ''
      }`}
    >
      {loading ? (
        <div>
          <Waiting /> Loading...
        </div>
      ) : (
        <div>
          {totalHitsCount > 0 ? (
            <div>
              {noFrom}-{myNoTo} / {totalHitsCount} matches
            </div>
          ) : (
            <div>No matches found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchSuccess;
