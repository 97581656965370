import React, { useState } from 'react';
// import Tooltip from 'react-tooltip-lite';

let myHit = (myKey, text) => {
  return (
    <strong key={myKey} className='text-success'>
      {text}
    </strong>
  );
};

let mySlice = (myKey, utterance, from, to) => {
  return <span key={myKey}>{utterance.slice(from, to)}</span>;
};

let myActivity = (myKey, activityText) => {
  return (
    <span key={myKey}>
      {/* <Tooltip
        content={<span>Extra-corpus material not included in word-count.</span>}
        distance={3}
        tagName='span'
      > */}
      <span className='activity'>{activityText} </span>
      {/* </Tooltip> */}
    </span>
  );
};

const createJSX = (hit, activities, utterance, myBounds) => {
  let myLeftJSX = [];
  let myKeyJSX = [];
  let myRightJSX = [];
  let myKey = 0;
  let myCursor = myBounds.start;

  if (activities !== undefined) {
    activities.forEach(activity => {
      if (
        myBounds.start <= activity.position &&
        activity.position <= myBounds.end
      ) {
        if (activity.position <= hit.position) {
          // activity is in myLeftJSX
          myLeftJSX.push(
            mySlice(myKey++, utterance, myCursor, activity.position)
          );
          myLeftJSX.push(myActivity(myKey++, activity.activity));
          myCursor = activity.position;
        } else if (activity.position >= hit.position + hit.keyText.length) {
          // activity is in myRightJSX
          if (myCursor <= hit.position) {
            myLeftJSX.push(mySlice(myKey++, utterance, myCursor, hit.position));
            myKeyJSX.push(myHit(myKey++, hit.keyText));
            myCursor = hit.position + hit.keyText.length;
          }
          myRightJSX.push(
            mySlice(myKey++, utterance, myCursor, activity.position)
          );
          myRightJSX.push(myActivity(myKey++, activity.activity));
          myCursor = activity.position;
        } else {
          // activity is in myHitJSX
          console.log(myCursor, hit.position);
          myLeftJSX.push(mySlice(myKey++, utterance, myCursor, hit.position));
          if (myCursor < hit.position) {
            myCursor = hit.position;
          }

          myKeyJSX.push(
            myHit(myKey++, utterance.slice(myCursor, activity.position))
          );

          myKeyJSX.push(myActivity(myKey++, activity.activity));
          myCursor = activity.position;
        }
      }
    });

    // when not at end
    if (myCursor < myBounds.end) {
      if (myCursor <= hit.position) {
        // still need to fill up myLeftJSX and the rest
        myLeftJSX.push(mySlice(myKey++, utterance, myCursor, hit.position));
        myKeyJSX.push(myHit(myKey++, hit.keyText));
        myRightJSX.push(
          mySlice(
            myKey++,
            utterance,
            hit.position + hit.keyText.length,
            myBounds.end
          )
        );
      } else if (myCursor >= hit.position + hit.keyText.length) {
        // just fill up myRightJSX
        myRightJSX.push(mySlice(myKey++, utterance, myCursor, myBounds.end));
      } else {
        // fill up myKeyJSX and myRightJSX
        myKeyJSX.push(
          myHit(
            myKey++,
            utterance.slice(myCursor, hit.position + hit.keyText.length)
          )
        );
        myRightJSX.push(
          mySlice(
            myKey++,
            utterance,
            hit.position + hit.keyText.length,
            myBounds.end
          )
        );
      }
    }
  } else {
    // no activities in myBounds
    // myLeftJSX.push(
    //   <span key={myKey++}>{utterance.slice(myBounds.start, hit.position)}</span>
    // );
    myLeftJSX.push(mySlice(myKey++, utterance, myBounds.start, hit.position));
    myKeyJSX.push(myHit(myKey++, hit.keyText));
    myRightJSX.push(
      mySlice(
        myKey++,
        utterance,
        hit.position + hit.keyText.length,
        myBounds.end
      )
    );
  }
  return { myLeftJSX, myKeyJSX, myRightJSX };
};

export const createLongJSX = (utterance, activities, hit) => {
  const myBounds = {
    start: 0,
    end: utterance.length,
  };
  const { myLeftJSX, myKeyJSX, myRightJSX } = createJSX(
    hit,
    activities,
    utterance,
    myBounds
  );

  return (
    <span className='full-content'>
      {myLeftJSX}
      {myKeyJSX}
      {myRightJSX}
    </span>
  );
};

export const createShortJSX = (hit, index, activities, utterance) => {
  const myBounds = {
    start: hit.position - hit.leftText.length,
    end: hit.position + hit.keyText.length + hit.rightText.length,
  };
  if (myBounds.start < 0) {
    myBounds.start = 0;
  }
  if (myBounds.end > utterance.length) {
    myBounds.end = utterance.length;
  }
  const { myLeftJSX, myKeyJSX, myRightJSX } = createJSX(
    hit,
    activities,
    utterance,
    myBounds
  );

  return (
    <span>
      <div className='row px-0 flex-nowrap' id={`${index}-shortText`}>
        <div className='col hit-content-left'>
          <div className='inner-div'>{myLeftJSX}</div>
        </div>
        <div className='auto key-content'>
          <div className='text-success'>{myKeyJSX}</div>
        </div>
        <div className='col hit-content-right'>{myRightJSX}</div>
      </div>
    </span>
  );
};

export const formatBytes = (a, b = 2) => {
  if (0 === a) return '0 Bytes';
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    ' ' +
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
  );
};
