const Scribe = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 89' {...props}>
      <path
        d='m98.6,47.43l-6.66-6.66c-.9-.9-2.11-1.4-3.39-1.4s-2.48.5-3.39,1.4l-28.11,28.1c-.47.47-.83,1.02-1.07,1.64l-4.28,10.94c-.7,1.8-.29,3.77,1.07,5.13.93.93,2.13,1.41,3.36,1.41.59,0,1.19-.11,1.77-.34l10.94-4.28h0c.62-.24,1.17-.6,1.64-1.07l28.11-28.11c1.87-1.87,1.87-4.91,0-6.77Zm-31.61,31.31l-10.46,4.09,4.06-10.42,17.69-17.69,6.36,6.36-17.66,17.66Zm21.2-21.2l-6.36-6.36,6.73-6.73,6.36,6.36-6.73,6.73Z'
        strokeWidth='0'
      />
      <path
        d='m44.51,84H10.5c-3.03,0-5.5-2.47-5.5-5.5V10.5c0-3.03,2.47-5.5,5.5-5.5h22.5v19.5c0,4.69,3.81,8.5,8.5,8.5h19.5v22.35l5-5v-20.85c0-2.88-1.12-5.59-3.16-7.62L44.12,3.16c-2.04-2.04-4.74-3.16-7.62-3.16H10.5C4.71,0,0,4.71,0,10.5v68c0,5.79,4.71,10.5,10.5,10.5h35.59c-.91-1.53-1.45-3.24-1.58-5ZM38,5.22c.97.26,1.86.75,2.59,1.47l18.72,18.72c.73.73,1.21,1.62,1.47,2.59h-19.28c-1.93,0-3.5-1.57-3.5-3.5V5.22Z'
        strokeWidth='0'
      />
    </svg>
  );
};

export default Scribe;
