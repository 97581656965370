import React, { Fragment, useEffect } from 'react';
import { BREAKPOINT_LG, BREAKPOINT_SM } from '../includes/constants';
import DownloadIcon from './icons/DownloadIcon';

const ExportCard = ({ link, params, loading, screenWidth }) => {
  const myParams = new URLSearchParams(params);
  const myLink = `${link}${myParams}`;

  useEffect(() => {
    if (loading) {
      document.querySelectorAll('.export-card')[0].classList.add('disabled');
    } else {
      document.querySelectorAll('.export-card')[0].classList.remove('disabled');
    }
  }, [loading]);

  const exportXLSXBadge = (
    <div className={`${screenWidth < BREAKPOINT_SM ? 'w-50' : ''}`}>
      <a href={myLink} download>
        <div
          className={`btn btn-primary export-card ${
            screenWidth < BREAKPOINT_LG ? 'mb-1' : 'mb-3'
          } ${screenWidth < BREAKPOINT_SM ? 'w-100' : ''}`}
          data-tip
          data-for='csv-tip'
        >
          <DownloadIcon width='20px' />
          {/* <i className='fas fa-download fa-lg'></i> */}
        </div>
      </a>
    </div>
  );

  return <Fragment>{exportXLSXBadge}</Fragment>;
};

export default ExportCard;
