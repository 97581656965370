const LinkIcon = props => {
  return (
    <svg
      id='Layer_3'
      data-name='Layer 3'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 100 100'
      {...props}
      fill='none'
    >
      <polyline
        points='50 3 97 3 97 50'
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='5'
      />
      <polyline
        points='40 14 3 14 3 97 86 97 86 60'
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='5'
      />
      <line
        x1='97'
        y1='3'
        x2='50'
        y2='50'
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='5'
      />
    </svg>
  );
};

export default LinkIcon;
