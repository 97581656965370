import React, { Fragment } from 'react';
import { URL_HISCLASS } from '../includes/constants';
import ArrowUpIcon from './icons/ArrowUpIcon';

const SocialClass = ({ hisclass, startTooltip, endTooltip }) => {
  if (hisclass === null || hisclass === undefined) {
    return <></>;
  }

  // widthHeight in rem
  const widthHeight = {
    width: 28,
    height: 6,
  };

  const part1 = (
    <Fragment key='1'>
      Speaker belongs to the <br />{' '}
    </Fragment>
  );
  // Part2 and 3 depend on the hisclass
  // The hisclass can be any value from 1 through 13 or
  // as the single exception: "11/12".
  let part2;
  if (hisclass.split('/')[0] > 5) {
    part2 = (
      <Fragment key='2'>
        <strong>Lower social classes</strong> (Manual workers,{' '}
        <a target='_blank' rel='noopener noreferrer' href={URL_HISCLASS}>
          HISCLASS
        </a>{' '}
        6-13)
      </Fragment>
    );
  } else {
    part2 = (
      <Fragment key='2'>
        <strong>Higher social classes</strong> (non-manual workers,{' '}
        <a target='_blank' rel='noopener noreferrer' href={URL_HISCLASS}>
          HISCLASS
        </a>{' '}
        1-5)
      </Fragment>
    );
  }
  const part3 = (
    <Fragment key='3'>
      <br />
      <br />
      Specifically HISCLASS {hisclass}{' '}
    </Fragment>
  );
  let part4 = '';
  switch (String(hisclass)) {
    case '1':
      part4 = <strong key='4'>"Higher managers"</strong>;
      break;
    case '2':
      part4 = <strong key='4'>"Higher professionals"</strong>;
      break;
    case '3':
      part4 = <strong key='4'>"Lower managers"</strong>;
      break;
    case '4':
      part4 = (
        <strong key='4'>
          "Lower professionals, clerical and sales personnel"
        </strong>
      );
      break;
    case '5':
      part4 = <strong key='4'>"Lower clerical and sales personnel"</strong>;
      break;
    case '6':
      part4 = <strong key='4'>"Foremen"</strong>;
      break;
    case '7':
      part4 = <strong key='4'>"Medium-skilled workers"</strong>;
      break;
    case '8':
      part4 = <strong key='4'>"Farmers and fishermen"</strong>;
      break;
    case '9':
      part4 = <strong key='4'>"Lower-skilled workers"</strong>;
      break;
    case '10':
      part4 = <strong key='4'>"Lower-skilled farm workers"</strong>;
      break;
    case '11':
      part4 = <strong key='4'>"Unskilled workers"</strong>;
      break;
    case '11/12':
      part4 = <strong key='4'>"Unskilled (farm) workers"</strong>;
      break;
    case '12':
      part4 = <strong key='4'>"Unskilled farm workers"</strong>;
      break;
    case '13':
      part4 = <strong key='4'>"Unspecified workers"</strong>;
      break;
    default:
      part4 = <strong key='4'>"Not specified"</strong>;
  }
  const socialClassJSX = [part1, part2, part3, part4];

  // let socialClassIcon = '';
  // let textColor = '';
  // if (hisclass.split('/')[0] > 5) {
  //   socialClassIcon = 'fa-arrow-down';
  //   textColor = 'text-primary';
  // } else {
  //   socialClassIcon = 'fa-arrow-up';
  //   textColor = 'text-light';
  // }

  return (
    <a target='_blank' rel='noopener noreferrer' href={URL_HISCLASS}>
      <span
        className={`badge `}
        onPointerEnter={e => startTooltip(socialClassJSX, e, widthHeight)}
        onPointerLeave={e => endTooltip(e)}
      >
        <ArrowUpIcon
          width='16px'
          className={`arrow-up ${hisclass.split('/')[0] > 5 ? 'rotated' : ''}`}
        />
        {/* <i className={`fas ${socialClassIcon} ${textColor}`}></i> */}
      </span>
    </a>
  );
};

export default SocialClass;
