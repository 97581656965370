import axios from 'axios';
import { BASE_URL } from '../src/includes/constants';

export const axiosClient = axios.create({
  baseUrl: BASE_URL,

  timeout: 60000,
  headers: {
    'content-type': 'application/json',
  },
});

export default axiosClient;
