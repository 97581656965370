import React from 'react';
import { OBC_VERSION, APP_VERSION } from '../includes/constants';

const Contact = () => {
  return (
    <div className='col mx-auto'>
      <div className='mb-3 pb-3'>
        <div className='card outmost-card text-white bg-secondary mt-3 mb-4 mx-auto'>
          <div className='card-header'>Contact</div>

          <div className='card-body text-left'>
            <div className='card mb-3'>
              <div className='card-header'>General</div>
              <div className='card-body'>
                <a
                  href={`mailto:contact@obc-client.de?subject=Old Bailey Corpus ${OBC_VERSION}`}
                >
                  contact@obc-client.de
                </a>
              </div>
            </div>
            <div className='card'>
              <div className='card-header'>Technical issues</div>
              <div className='card-body'>
                <a
                  href={`mailto:support@obc-client.de?subject=Old Bailey Corpus ${OBC_VERSION}, v${APP_VERSION}`}
                >
                  support@obc-client.de
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
