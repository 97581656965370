import UtteranceService from './UtteranceServices';

export const getPage = async searchParams => {
  let result = [];
  const getThePage = async () => {
    const response = await UtteranceService.getPageNew(searchParams);
    if (!response) return;
    const data = response;
    // const data = await response.data;
    result = data;
  };
  await getThePage();
  return result;
};

export default getPage;
