import React, { useState, useEffect } from 'react';
import FileHandling from './components/FileHandling';
import Search from './components/Search';
// import Axios from 'axios';
import UtteranceServices from './services/UtteranceServices';
import './obc.css';

import './App.css';
import OBCNavbar from './components/OBCNavbar';
import Footer from './components/Footer';
import UserContext from './context/UserContext';
import Users from './components/Users';
import Impressum from './components/Impressum';
import Datenschutz from './components/Datenschutz';
import Contact from './components/Contact';

const PageJSX = ({ userData, mode, firstLoad, setFirstLoad, screenWidth }) => {
  let myJSX = (
    <Search
      firstLoad={firstLoad}
      setFirstLoad={setFirstLoad}
      screenWidth={screenWidth}
    />
  );
  switch (mode) {
    case 'data':
      if (
        userData.user &&
        (userData.user.role === 'Administrator' ||
          userData.user.role === 'Data Handler')
      ) {
        myJSX = <FileHandling />;
      }
      break;
    case 'admin':
      if (userData.user && userData.user.role === 'Administrator') {
        myJSX = <Users />;
      }
      break;
    case 'impressum':
      myJSX = <Impressum />;
      break;
    case 'datenschutz':
      myJSX = <Datenschutz />;
      break;
    case 'contact':
      myJSX = <Contact />;
      break;
    default:
  }
  return myJSX;
};

function App() {
  const [mode, setMode] = useState('search');
  const [firstLoad, setFirstLoad] = useState(false);
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined,
  });
  const [screenWidth, setScreenWidth] = useState(1024);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  // Initialize page
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    !userData.user && setMode('search');
  }, [userData.user]);

  // Check on startup if there is a valid token in local storage
  // If so, load corresponding user
  useEffect(() => {
    const checkedLoggedIn = async () => {
      let token = localStorage.getItem('auth-token');
      if (token === null || token === '') {
        localStorage.setItem('auth-token', '');
        token = '';
      } else {
        try {
          const tokenRes = await UtteranceServices.tokenIsValid(token);
          if (tokenRes.data) {
            const userRes = await UtteranceServices.getUser(token);
            setUserData({
              token: token,
              user: { name: userRes.data.name, role: userRes.data.role },
            });
          }
        } catch {
          console.log('Token expired');
        }
      }
    };
    checkedLoggedIn();
  }, []);

  return (
    <div className='App no-scroll'>
      <UserContext.Provider
        value={{
          userData,
          setUserData,
        }}
      >
        <OBCNavbar
          mode={mode}
          setMode={setMode}
          setFirstLoad={setFirstLoad}
          screenWidth={screenWidth}
        />
        <div className='container-fluid'>
          <PageJSX
            userData={userData}
            mode={mode}
            firstLoad={firstLoad}
            setFirstLoad={setFirstLoad}
            screenWidth={screenWidth}
          />
        </div>
        <Footer setMode={setMode} />
      </UserContext.Provider>
    </div>
  );
}

export default App;
