import React from 'react';
// import { formatBytes } from '../includes/textTools';

const lastModifiedDate = lastModified => {
  let myDate = new Date(lastModified);
  return (
    ('0' + myDate.getDate()).slice(-2) +
    '.' +
    ('0' + (myDate.getMonth() + 1)).slice(-2) +
    '.' +
    myDate.getFullYear()
  );
  // return `${myDate.getDate()}.${myDate.getMonth()}.${myDate.getFullYear()}`;
};

const FileListElement = ({ file, deleteFile, index }) => {
  return (
    <div>
      <li className='list-group-item d-flex justify-content-between align-items-center flex-wrap'>
        <div className='nowrap-row'>{file.file.name}</div>
        <div className='nowrap-row'>
          <span>
            <span className='badge badge-secondary badge-pill mr-1'>
              {lastModifiedDate(file.file.lastModified)}
            </span>
            <span className='badge badge-secondary badge-pill mr-3'>
              {`${(file.file.size / 1024).toFixed(2)} kB`}
              {/* {formatBytes(file.size, 2)} */}
            </span>
            {file.uploaded ? (
              <i className='far fa-check-circle success'></i>
            ) : (
              <i
                className='far fa-trash-alt pointer-cursor'
                onClick={() => deleteFile(index)}
              ></i>
            )}
          </span>
        </div>
      </li>
    </div>
  );
};

export default FileListElement;
