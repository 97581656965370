import React from 'react';
import { APP_VERSION } from '../includes/constants';

const Footer = ({ setMode }) => {
  return (
    <div className='footer' id='obc-footer'>
      <div className='row w-100 justify-content-between align-items-center mx-0'>
        <div>
          <a
            href='http://nitram-media.de'
            target='_blank'
            rel='noopener noreferrer'
          >
            <span>&copy; 2022 nitram-media</span>
          </a>
        </div>
        <div classname=''>v{APP_VERSION} </div>

        <div>
          <div></div>
          {/* <span
            className='mr-2 pointer-cursor secondary'
            onClick={() => setMode('impressum')}
          >
            Impressum
          </span>
          <span
            className='mr-2 pointer-cursor'
            onClick={() => setMode('datenschutz')}
          >
            Datenschutz
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
