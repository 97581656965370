import React, { useState, useEffect } from 'react';
// import UserContext from '../context/UserContext';
import UtteranceServices from '../services/UtteranceServices';
import NewUserListElement from './NewUserListElement';
import UserListElement from './UserListElement';
// import UserContext from '../context/UserContext';

const Users = () => {
  const [users, setUsers] = useState([]);
  // const [noOfAdmins, setNoOfAdmins] = useState(1);
  // const { userData, setUserData } = useContext(UserContext);

  useEffect(() => {
    const getAllUsers = async () => {
      const myUsers = await UtteranceServices.getAllUsers();
      // let myAdmins = 0;

      setUsers(myUsers.data);
      return myUsers;
    };

    getAllUsers();
  }, []);

  const reloadUsers = async () => {
    const myUsers = await UtteranceServices.getAllUsers();

    setUsers(myUsers.data);
    // return myUsers;
  };

  return (
    <div>
      {/* {userData.user && ( */}
      <div className='container my-3 mw-100'>
        <div className='row'>
          <div className='col mx-auto mb-3'>
            <div className='card outmost-card text-white bg-primary mb-3 mx-auto'>
              <div className='card-header'>
                <i className='fas fa-users'></i>
                <span> Users</span>
              </div>
              <div className='card-body'>
                <div className='text-right'>
                  <label>Users: {users.length}</label>
                </div>
                <NewUserListElement reloadUsers={reloadUsers} />
                {users.length !== 0 &&
                  users.map((user, index) => {
                    return (
                      <UserListElement
                        key={index}
                        index={index + 1}
                        user={user}
                        reloadUsers={reloadUsers}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default Users;
