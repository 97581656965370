import React from 'react';

const Role = ({ role, startTooltip, endTooltip }) => {
  // widthHeight in rem
  const widthHeight = {
    width: 10,
    height: 3.2,
  };
  const roleText = (
    <div>
      Role in trial:
      <br />
      <strong>{role}</strong>
    </div>
  );

  return (
    <span
      className='badge badge-pill badge-success role-badge obc-tag'
      onMouseOver={e => startTooltip(roleText, e, widthHeight)}
      onPointerLeave={e => endTooltip(e)}
    >
      <span className='role-text'>{role}</span>
    </span>
  );
};

export default Role;
