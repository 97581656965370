const ScaleIcon = props => {
  return (
    <svg
      id='Scale'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 99 77.8'
      // stroke='currentColor'
      {...props}
    >
      <line
        x1='13'
        y1='76.8'
        x2='83'
        y2='76.8'
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='5'
      />
      <line
        x1='13'
        y1='5.8'
        x2='39'
        y2='5.8'
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='5'
      />
      <line
        x1='57'
        y1='5.8'
        x2='83'
        y2='5.8'
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='5'
      />
      <line
        x1='48'
        y1='75.8'
        x2='48'
        y2='16.8'
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='5'
      />
      <circle
        cx='48'
        cy='10.9'
        r='9.9'
        fill='none'
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='5'
      />
      <path
        d='m19,13.8L1,46.8c1.59,8.53,9.03,15,18,15s16.41-6.47,18-15L19,13.8Z'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='5'
      />
      <line
        x1='1'
        y1='46.8'
        x2='37'
        y2='46.8'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='5'
      />
      <path
        d='m77,13.8l-18,33c1.59,8.53,9.03,15,18,15s16.41-6.47,18-15l-18-33Z'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='5'
      />
      <line
        x1='59'
        y1='46.8'
        x2='95'
        y2='46.8'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='5'
      />
    </svg>
  );
};

export default ScaleIcon;
