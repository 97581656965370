import React from 'react';

const SaveSuccess = ({ number }) => {
  return (
    <div className='alert alert-dismissible alert-success col-3 mx-auto mt-4'>
      <button type='button' className='close' data-dismiss='alert'>
        &times;
      </button>
      <strong>{number}</strong> utterances stored
    </div>
  );
};

export default SaveSuccess;
