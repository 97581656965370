import React from 'react';

const Year = ({ year, periods, startTooltip, endTooltip }) => {
  let myPeriods = null;

  // widthHeight in rem
  const widthHeight = {
    width: 8.5,
    height: 8,
  };

  if (periods === undefined) return <></>;
  myPeriods = (
    <>
      <strong>Corpus subperiods</strong>
      <br />
      p2: <strong>{periods.p2}</strong> <br />
      p3: <strong>{periods.p3}</strong> <br />
      p4: <strong>{periods.p4}</strong> <br />
      p5: <strong>{periods.p5}</strong> <br />
      p6: <strong>{periods.p6}</strong> <br />
    </>
  );

  return (
    <span
      className='badge badge-pill badge-light obc-tag'
      onPointerEnter={e => startTooltip(myPeriods, e, widthHeight)}
      onPointerLeave={e => endTooltip(e)}
    >
      {year}
    </span>
  );
};

export default Year;
