import React, { useEffect, useState, useRef } from 'react';
// import Pagination from './Pagination';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
// import SearchSuccess from './SearchSuccess';
import {
  PAGE_SIZE,
  // BASE_URL,
  // BREAKPOINT_LG,
  BREAKPOINT_MD,
} from '../includes/constants';
import getPage from '../services/getPage';
import getTotalHits from '../services/getTotalHits';
// import ExportCard from './ExportCard';
import ToolTip from './ToolTip';
import TagsSlider from './TagsSlider';
import TableButtons from './TableButtons';

const Table = ({
  searchParams: {
    fromYear,
    untilYear,
    searchTerm,
    searchType,
    flags,
    wholeWordOnly,
    contextLeft,
    contextRight,
    obcVersion,
    includeActivity,
  },
  handleSearch,
  firstLoad,
  setFirstLoad,
  screenWidth,
}) => {
  // holds the loading status of table
  const [loading, setLoading] = useState(false);

  // holds all hits of page
  const [docsWithHits, setDocsWithHits] = useState([]);

  // holds total number of hits
  const [totalHitsCount, setTotalHitsCount] = useState(0);

  // holds the actual page
  const [page, setPage] = useState(0);

  // holds the actual search parameters
  const [mySearchParams, setMySearchParams] = useState({
    page: 0,
    fromYear: fromYear,
    untilYear: untilYear,
    searchTerm: searchTerm,
    searchType: searchType,
    wholeWordOnly: wholeWordOnly,
    includeActivity: includeActivity,
    limit: PAGE_SIZE,
    flags: flags,
    contextLeft: contextLeft,
    contextRight: contextRight,
    obcVersion: obcVersion,
    totalHitsCount: totalHitsCount,
  });

  const [footerHeight] = useState(100);

  const [tagsExpandedByUser, setTagsExpandedByUser] = useState(false);

  let myTTDurationTimeout = useRef(0);

  const [tooltip, setTooltip] = useState({
    show: false,
    jsx: <div></div>,
    x: 0,
    y: 0,
    xOffset: 0,
    yOffset: 20,
    hPosition: 'left',
    hideAfter: 3000,
    startDelay: 300,
    width: 24,
    height: 6,
  });

  // Retract tagSlider when Screen is large
  useEffect(() => {
    if (screenWidth > BREAKPOINT_MD) {
      setTagsExpandedByUser(false);
    }
  }, [screenWidth]);

  // Initialize page
  useEffect(() => {
    getAPage({ page: 0 });
  }, []);

  const getAPage = ({ page }) => {
    let myHits = [];

    async function getThatPage() {
      setLoading(true);
      let res = await getPage({ ...mySearchParams, page: page });

      const myDocs = res.data;

      myDocs.map(myDoc => {
        return myDoc.hits.map(hit => {
          return myHits.push({ ...myDoc, hit: hit });
        });
      });
      myHits = myHits.slice(0, PAGE_SIZE);
      setDocsWithHits(myHits);

      if (firstLoad) {
        let { totalHits } = await getTotalHits({
          ...mySearchParams,
        });
        setTotalHitsCount(totalHits);
        setMySearchParams({
          ...mySearchParams,
          totalHitsCount: totalHits,
        });
        setFirstLoad(false);
      }

      setLoading(false);
    }
    setTagsExpandedByUser(false);
    getThatPage();
  };

  // gets a new page when Pagination is clicked
  const getAnotherPage = newPage => {
    setPage(newPage);
    getAPage({ page: newPage });
  };

  const myHandleSearch = () => {
    handleSearch(false);
  };

  const startTooltip = (jsx, e, widthHeight, hPosition = 'left') => {
    let screenTooNarrow = false;
    const convertRemToPixels = rem => {
      return (
        rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
      );
    };

    let tooltipDim = {
      width: convertRemToPixels(widthHeight.width),
      height: convertRemToPixels(widthHeight.height),
    };

    let myBodyWidth = document.querySelector('body').clientWidth;
    // on narrow screens the width of the tooltip is restricted (margin of 6px left and right)
    if (tooltipDim.width > myBodyWidth - 12) {
      tooltipDim.width = myBodyWidth - 12;
      screenTooNarrow = true;
    }

    const createNewTooltip = () => {
      // Csalculate x and y position
      let myX;
      let myY = e.clientY + window.scrollY + tooltip.yOffset;

      // on narrow screen the tooltip is shown centered (margin of 6px left and right)
      if (screenTooNarrow) {
        myX = 6;
      } else {
        if (hPosition === 'right') {
          myX = e.clientX + tooltip.xOffset;
        } else {
          myX = e.clientX - tooltipDim.width - tooltip.xOffset;
        }
        // if the tooltip is too far left, keep it at 6px from the edge
        if (myX < 6) myX = 6;

        // if the tooltip is too far right, keep it at 6px from the edge
        if (myX + tooltipDim.width > myBodyWidth - 6)
          myX = myBodyWidth - 6 - tooltipDim.width;
      }

      // Adjust myY so that the tooltip is not hidden by footer
      // when the tooltip is close to the bottom
      if (
        e.clientY + footerHeight + tooltipDim.height + tooltip.yOffset >
        window.innerHeight
      ) {
        myY = e.clientY + window.scrollY - tooltipDim.height - tooltip.yOffset;
      }

      // Create the new tooltip
      let newTT = {
        show: true,
        jsx: jsx,
        hPosition: hPosition,
        x: myX,
        y: myY,
        width: tooltipDim.width,
        height: tooltipDim.height,
      };

      return newTT;
    };

    setTooltip(tooltip => ({ ...tooltip, ...createNewTooltip() }));

    // Reset the timer
    clearTimeout(myTTDurationTimeout.current);

    // Setup the timer to hide the tooltip after tooltip.hideAfter
    myTTDurationTimeout.current = setTimeout(() => {
      endTooltip();
    }, tooltip.hideAfter);
  };

  const endTooltip = e => {
    let hideTT = {
      show: false,
    };
    setTooltip(tooltip => ({ ...tooltip, ...hideTT }));
  };

  return (
    <div className='container-fluid my-3' id='obc-table'>
      <TableButtons
        totalHitsCount={totalHitsCount}
        page={page}
        loading={loading}
        myHandleSearch={myHandleSearch}
        mySearchParams={mySearchParams}
        getAnotherPage={getAnotherPage}
        screenWidth={screenWidth}
      />
      <div className='row'>
        <div className='container-fluid'>
          <TableHeader
            screenWidth={screenWidth}
            tagsExpandedByUser={tagsExpandedByUser}
            setTagsExpandedByUser={setTagsExpandedByUser}
          />
          <div className='content'>
            {docsWithHits !== [] &&
              docsWithHits.map((hit, index) => {
                return (
                  <TableRow
                    row={hit}
                    key={index}
                    index={index + 1 + page * PAGE_SIZE}
                    startTooltip={startTooltip}
                    endTooltip={endTooltip}
                    screenWidth={screenWidth}
                    tagsExpandedByUser={tagsExpandedByUser}
                    setTagsExpandedByUser={setTagsExpandedByUser}
                  />
                );
              })}
            {screenWidth < BREAKPOINT_MD && (
              <TagsSlider
                docsWithHits={docsWithHits}
                startTooltip={startTooltip}
                endTooltip={endTooltip}
                screenWidth={screenWidth}
                page={page}
                tagsExpandedByUser={tagsExpandedByUser}
                setTagsExpandedByUser={setTagsExpandedByUser}
              />
            )}
          </div>
        </div>
      </div>
      <ToolTip tooltip={tooltip} />

      <div className='row mb-4'></div>
    </div>
  );
};

export default Table;
