import React from 'react';
import ScaleIcon from './ScaleIcon';

const Offence = ({ offenceTags, startTooltip, endTooltip }) => {
  if (offenceTags === undefined) {
    return <></>;
  }
  const { offCat, offSub, verCat, verSub, punCat, punSub } = offenceTags;
  // widthHeight in rem
  const widthHeight = {
    width: 24,
    height: 8,
  };

  const offenceText = (
    <div className='offence-tooltip-table'>
      <div className='tooltip-column'>
        <div className=''>offenceCategory: </div>
        <div className=''>offenceSubcategory: </div>
        <div className=''>verdictCategory: </div>
        <div className=''>verdictSubcategory: </div>
        <div className=''>punishmentCategory: </div>
        <div className=''>punishmentSubcategory: </div>
      </div>
      <div className='tooltip-column'>
        <div>
          <strong>{offCat}</strong>
        </div>
        <div>
          <strong>{offSub}</strong>
        </div>
        <div>
          <strong>{verCat}</strong>
        </div>
        <div>
          <strong>{verSub}</strong>
        </div>
        <div>
          <strong>{punCat}</strong>
        </div>
        <div>
          <strong>{punSub}</strong>
        </div>
      </div>
    </div>
  );

  return (
    <span
      // className={`badge badge-pill badge-light obc-tag`}
      onPointerEnter={e => startTooltip(offenceText, e, widthHeight)}
      onPointerLeave={e => endTooltip(e)}
    >
      <ScaleIcon width='18px' className='offence' />
      {/* <i className='fas fa-balance-scale text-primary'></i> */}
    </span>
  );
};

export default Offence;
