import React from 'react';
// import { BREAKPOINT_SM, BREAKPOINT_LG } from '../includes/constants';

import HiscoLabel from './HiscoLabel';
import OldBaileyLink from './OldBaileyLink';
import ScribePrinter from './ScribePrinter';
import GenderIcon from './Gender';
import SocialClass from './SocialClass';
import Year from './Year';
import Role from './Role';
import Offence from './Offence';

// import Tooltip from 'react-tooltip-lite';

const TagRow = ({
  row: {
    utterance,
    activities,
    hiscoTags,
    printTags,
    periodTags,
    offenceTags,
    year,
    url,
    sex,
    age,
    role,
    hit,
  },
  index,
  startTooltip,
  endTooltip,
}) => {
  // widthHeight in rem
  // const widthHeight = {
  //   width: 18,
  //   height: 2,
  // };

  // striping
  let bgColor = 'bg-dark';
  if (index % 2 === 0) {
    bgColor = 'bg-primary';
  }

  return (
    <div className='row justify-content-between flex-nowrap overflow'>
      <div className={`col text-left ${bgColor} tag-col`}>
        <div className={`row tag-row`}>
          <div className='url-col gen-tag-col'>
            <OldBaileyLink
              url={url}
              startTooltip={startTooltip}
              endTooltip={endTooltip}
            />
          </div>

          <div className='year-col gen-tag-col'>
            <Year
              year={year}
              periods={periodTags}
              index={index}
              startTooltip={startTooltip}
              endTooltip={endTooltip}
            />
          </div>

          <div className='gender-col gen-tag-col'>
            <GenderIcon
              sex={sex}
              age={age}
              index={index}
              startTooltip={startTooltip}
              endTooltip={endTooltip}
            />
          </div>

          <div className='hisco-col gen-tag-col'>
            {hiscoTags !== undefined && (
              <HiscoLabel
                hiscoLabel={hiscoTags.hiscoLabel}
                hiscoCode={hiscoTags.hiscoCode}
                index={index}
                startTooltip={startTooltip}
                endTooltip={endTooltip}
              />
            )}
          </div>

          <div className='class-col gen-tag-col'>
            {hiscoTags !== undefined && (
              <SocialClass
                hisclass={hiscoTags.hisclass}
                index={index}
                startTooltip={startTooltip}
                endTooltip={endTooltip}
              />
            )}
          </div>

          <div className='role-col gen-tag-col'>
            {role !== undefined && (
              <Role
                role={role}
                index={index}
                startTooltip={startTooltip}
                endTooltip={endTooltip}
              />
            )}
          </div>

          <div className='offence-col gen-tag-col'>
            <Offence
              offenceTags={offenceTags}
              index={index}
              startTooltip={startTooltip}
              endTooltip={endTooltip}
            />
          </div>

          <div className='scribe-col gen-tag-col'>
            <ScribePrinter
              printtags={printTags}
              index={index}
              startTooltip={startTooltip}
              endTooltip={endTooltip}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagRow;
