import React from 'react';
import {
  PAGE_SIZE,
  BASE_URL,
  BREAKPOINT_LG,
  BREAKPOINT_SM,
} from '../includes/constants';
import SearchSuccess from './SearchSuccess';
import ExportCard from './ExportCard';
import Pagination from './Pagination';

const TableButtons = ({
  totalHitsCount,
  page,
  loading,
  myHandleSearch,
  mySearchParams,
  getAnotherPage,
  screenWidth,
}) => {
  let mySearchSuccess = (
    <SearchSuccess
      totalHitsCount={totalHitsCount}
      noFrom={page * PAGE_SIZE + 1}
      noTo={page * PAGE_SIZE + PAGE_SIZE}
      loading={loading}
      screenWidth={screenWidth}
    />
  );
  let myChangeSearchButton = (
    <button
      type='button'
      className={`btn btn-primary ${
        screenWidth <= BREAKPOINT_LG ? 'mb-1' : 'mb-3'
      } ${screenWidth < BREAKPOINT_SM ? 'w-50 mr-1' : ''}`}
      onClick={myHandleSearch}
    >
      Change search
    </button>
  );
  let myExportCard = (
    <ExportCard
      link={`${BASE_URL}/xls?`}
      params={mySearchParams}
      loading={loading}
      screenWidth={screenWidth}
    />
  );
  let myPagination = (
    <Pagination
      page={page}
      maxPages={Math.ceil(totalHitsCount / PAGE_SIZE)}
      getPage={getAnotherPage}
      loading={loading}
      screenWidth={screenWidth}
    />
  );

  return (
    <div id='table-buttons'>
      {screenWidth > BREAKPOINT_LG && (
        <div
          className='row justify-content-between align-items-center'
          id='obc-table-button-row'
        >
          {mySearchSuccess}
          {myChangeSearchButton}
          {myExportCard}
          {myPagination}
        </div>
      )}

      {screenWidth >= BREAKPOINT_SM && screenWidth <= BREAKPOINT_LG && (
        <>
          <div className='row justify-content-between align-items-center flex-nowrap'>
            {mySearchSuccess}

            {myChangeSearchButton}
            {myExportCard}
          </div>
          <div className='row justify-content-center align-items-center'>
            {myPagination}
          </div>
        </>
      )}
      {screenWidth < BREAKPOINT_SM && (
        <>
          <div className='row justify-content-between align-items-center'>
            {mySearchSuccess}
          </div>
          <div className='row justify-content-between align-items-center flex-nowrap'>
            {myChangeSearchButton}
            {myExportCard}
          </div>
          <div className='row justify-content-between align-items-center'>
            {myPagination}
          </div>
        </>
      )}
    </div>
  );
};

export default TableButtons;
