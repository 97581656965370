import React from 'react';

const ExpandedText = ({ longJSX, handleOnClick, id }) => {
  return (
    <div
      className='expanded-text'
      onClick={handleOnClick}
      id={id}
      // style={{ top: yPos + 'px' }}
    >
      {longJSX}
    </div>
  );
};

export default ExpandedText;
