import React, { useState, useEffect, useContext, useRef } from 'react';
import { BREAKPOINT_SM, OBC_VERSION, PUBLIC_URL } from '../includes/constants';
import LoginOutCard from './LoginOutCard';
import UserContext from '../context/UserContext';
import ChangeUserData from './ChangeUserData';
import ForgotPassword from './ForgotPassword';
import UserIcon from './icons/UserIcon';

const OBCNavbar = ({ mode, setMode, setFirstLoad, screenWidth }) => {
  const [rolledOutMode, setRolledOutMode] = useState(false);
  const [showDataButton, setShowDataButton] = useState(false);
  const [showUserButton, setShowUserButton] = useState(false);
  const { userData } = useContext(UserContext);

  const timer = useRef(null);

  const clickOnUser = () => {
    if (rolledOutMode) {
      setRolledOutMode(false);
    } else {
      setRolledOutMode('login');

      timer.current = setTimeout(() => {
        setRolledOutMode(false);
      }, 5000);
    }
  };

  useEffect(() => {
    if (
      !rolledOutMode ||
      rolledOutMode === 'changeUser' ||
      rolledOutMode === 'forgotPassword'
    ) {
      clearTimeout(timer.current);
    }
  }, [rolledOutMode]);

  useEffect(() => {
    let myShowDataButton = false;
    let myShowUserButton = false;
    if (userData.user) {
      if (
        userData.user.role === 'Data Handler' ||
        userData.user.role === 'Administrator'
      ) {
        myShowDataButton = true;
        let myUserIcon = document.getElementById('obc-user');
        myUserIcon && myUserIcon.classList.add('success');
        if (userData.user.role === 'Administrator') {
          myShowUserButton = true;
        }
      }
    }
    setShowDataButton(myShowDataButton);
    setShowUserButton(myShowUserButton);
  }, [userData.user]);

  useEffect(() => {
    let mySearchLink = document.getElementById('searchLink');
    let myDataLink = document.getElementById('dataLink');
    let myAdminLink = document.getElementById('userLink');
    switch (mode) {
      case 'data':
        mySearchLink.classList.remove('active');
        myDataLink.classList.add('active');
        myAdminLink.classList.remove('active');
        break;
      case 'admin':
        mySearchLink.classList.remove('active');
        myDataLink.classList.remove('active');
        myAdminLink.classList.add('active');
        break;
      case 'impressum':
      case 'datenschutz':
      case 'contact':
        mySearchLink.classList.remove('active');
        myDataLink.classList.remove('active');
        myAdminLink.classList.remove('active');
        break;
      default:
        mySearchLink.classList.add('active');
        myDataLink.classList.remove('active');
        myAdminLink.classList.remove('active');
    }
  }, [mode]);

  useEffect(() => {
    if (screenWidth >= BREAKPOINT_SM) {
      document.getElementById('obcNavbar').classList.remove('show');
    }
  }, [screenWidth]);

  const setMyMode = newMode => {
    if (newMode !== mode) {
      setMode(newMode);
    }
  };

  return (
    <div>
      <nav
        className='navbar navbar-expand-sm navbar-dark bg-primary'
        id='obcNavbarWrapper'
      >
        <div className='span navbar-brand'>
          The Old Bailey Corpus {OBC_VERSION}
        </div>
        <div>
          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#obcNavbar'
            aria-controls='obcNavbar'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
        </div>

        <div
          className='collapse navbar-collapse justify-content-between'
          id='obcNavbar'
        >
          <ul className='navbar-nav mr-auto'>
            <li
              id='searchLink'
              className='nav-item'
              data-toggle={screenWidth <= BREAKPOINT_SM ? 'collapse' : ''}
              data-target={screenWidth <= BREAKPOINT_SM ? '#obcNavbar' : ''}
            >
              <div
                className='nav-link'
                onClick={() => {
                  setMyMode('search');
                  setFirstLoad(true);
                }}
              >
                Search
              </div>
            </li>
            <li id='searchLink' className='nav-item dropdown pointer-cursor'>
              <div
                className='nav-link dropdown-toggle'
                data-toggle='dropdown'
                role='button'
                aria-haspopup='true'
                aria-expanded='false'
              >
                Download
              </div>
              <div className='dropdown-menu'>
                <a
                  href={`${PUBLIC_URL}OBC 3.0 2021-01-13 word count by periods.xlsx`}
                  download
                >
                  <div className='dropdown-item'>
                    <div className='row justify-content-between align-items-end'>
                      <span>Word Count by Periods </span>
                      <small>xlsx (175 kB)</small>
                    </div>
                  </div>
                </a>

                <div className='dropdown-divider'></div>

                <a
                  href={`${PUBLIC_URL}OBC 3.0 2021-01-13 word count by utterance.xlsx`}
                  download
                >
                  <div className='dropdown-item text-secondary'>
                    <div className='row justify-content-between align-items-end'>
                      <small>Word Count by Utterances</small>
                      <div>
                        <small>xlsx</small>
                        <small className='text-warning'> (38.5 MB)</small>
                      </div>
                    </div>
                  </div>
                </a>

                <div className='dropdown-divider'></div>

                <a
                  href={
                    PUBLIC_URL +
                    'OBC 3.0 2022-08-16 incl offence, verdict, punishment.zip'
                  }
                  download
                >
                  <div className='dropdown-item text-secondary'>
                    <div className='row justify-content-between align-items-end'>
                      <small>Complete Corpus</small>
                      <div>
                        <small>zip</small>
                        <small className='text-warning'> (58.0 MB)</small>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </li>
            <li
              id='dataLink'
              className='nav-item'
              hidden={!showDataButton}
              data-toggle={screenWidth <= BREAKPOINT_SM ? 'collapse' : ''}
              data-target={screenWidth <= BREAKPOINT_SM ? '#obcNavbar' : ''}
            >
              <div className='nav-link' onClick={() => setMyMode('data')}>
                Data Handling
              </div>
            </li>
            <li
              id='userLink'
              className='nav-item'
              hidden={!showUserButton}
              data-toggle={screenWidth <= BREAKPOINT_SM ? 'collapse' : ''}
              data-target={screenWidth <= BREAKPOINT_SM ? '#obcNavbar' : ''}
            >
              <div className='nav-link' onClick={() => setMyMode('admin')}>
                Users
              </div>
            </li>
          </ul>
          <div className='row flex-nowrap align-items-center justify-content-center contact-user-group'>
            {/* <i
              className='far fa-envelope mr-3 fa-lg pointer-cursor'
              onClick={() => setMyMode('contact')}
            ></i> */}
            <UserIcon
              width='20px'
              id='obc-user'
              onClick={clickOnUser}
              data-toggle={screenWidth <= BREAKPOINT_SM ? 'collapse' : ''}
              data-target={screenWidth <= BREAKPOINT_SM ? '#obcNavbar' : ''}
              className={userData.user ? 'logged-in' : ''}
            />
          </div>
        </div>
      </nav>

      <LoginOutCard
        rolledOutMode={rolledOutMode}
        setRolledOutMode={setRolledOutMode}
      />

      <ChangeUserData
        rolledOutMode={rolledOutMode}
        setRolledOutMode={setRolledOutMode}
      />

      <ForgotPassword
        rolledOutMode={rolledOutMode}
        setRolledOutMode={setRolledOutMode}
      />
    </div>
  );
};

export default OBCNavbar;
