import React, { useState, useEffect } from 'react';
// import UserContext from '../context/UserContext';
import UtteranceServices from '../services/UtteranceServices';

const ForgotPassword = ({ rolledOutMode, setRolledOutMode }) => {
  // const { userData, setUserData } = useContext(UserContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [newPassword, setNewPassword] = useState('');
  // const [newPasswordCheck, setNewPasswordCheck] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [msg, setMsg] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    let forgotPasswordCard = document.getElementById('forgot-password-card');
    if (forgotPasswordCard !== null) {
      if (rolledOutMode === 'forgotPassword') {
        forgotPasswordCard.classList.add('rolled-out');
      } else {
        forgotPasswordCard.classList.remove('rolled-out');
      }
    }
  }, [rolledOutMode]);

  const sendPassword = async e => {
    e.currentTarget.blur();
    try {
      let myRes = await UtteranceServices.resetPassword({
        name,
        email,
      });
      if (myRes.status === 200) {
        setShowSuccess(true);
        setMsg(myRes.data.msg);
        setName('');
        setEmail('');
        setTimeout(() => {
          setShowSuccess(false);
          setMsg(undefined);
          setRolledOutMode('login');
        }, 3000);
      }
    } catch (error) {
      setShowAlert(true);
      error.response.data.msg && setMsg(error.response.data.msg);
      setTimeout(() => {
        setShowAlert(false);
        setMsg(undefined);
      }, 3000);
      console.log(error);
    }
  };

  const forgotPasswordJSX = (
    <fieldset>
      <div className='form-group row px-3 mt-1 mb-3'>
        <label htmlFor='userName' className='mb-0'>
          <small>Username</small>
        </label>
        <input
          type='text'
          className='form-control form-control-sm'
          id='userName'
          name='userName'
          autoCorrect='off'
          autoCapitalize='off'
          autoComplete='off'
          spellCheck='false'
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className='form-group row px-3 mt-1 mb-3'>
        <label htmlFor='inputEmail' className='mb-0'>
          Email
        </label>
        <input
          id='inputEmail'
          className='form-control form-control-sm mt-0 mb-3'
          type='text'
          value={email}
          onChange={e => {
            setEmail(e.target.value);
          }}
          autoComplete='off'
          spellCheck='false'
        />
      </div>

      {showAlert && (
        <div className='row justify-content-center px-3 mb-2'>
          <div className='alert alert-dismissible alert-danger pr-0'>
            <button type='button' className='close p-0' data-dismiss='alert'>
              &times;
            </button>
            <div className='mr-3'>
              <strong>{msg}</strong>
            </div>
          </div>
        </div>
      )}
      {showSuccess && (
        <div className='row justify-content-center px-3 mb-2'>
          <div className='alert alert-dismissible alert-success pr-0'>
            <div className='mr-3'>
              <strong>{msg}</strong>
            </div>
          </div>
        </div>
      )}

      <div className='row justify-content-end px-3 mb-2'>
        <button
          id='sendPasswordButton'
          className='btn btn-sm btn-primary'
          onClick={sendPassword}
          type='submit'
        >
          Send new Password
        </button>
      </div>
    </fieldset>
  );

  return (
    <div
      id='forgot-password-card'
      className='m-1 card login-card bg-light change-user-card'
    >
      <div className='card-body py-0 pr-1'>
        <button
          type='button'
          className='close'
          onClick={() => setRolledOutMode(false)}
        >
          &times;
        </button>
        <form onSubmit={e => e.preventDefault()}>{forgotPasswordJSX}</form>
      </div>
    </div>
  );
};

export default ForgotPassword;
