import React, { useState, useContext, useEffect } from 'react';
import UserContext from '../context/UserContext';
import UtteranceServices from '../services/UtteranceServices';

const ChangeUserData = ({ rolledOutMode, setRolledOutMode }) => {
  const { userData, setUserData } = useContext(UserContext);
  const [name, setName] = useState(userData.user ? userData.user.name : '');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordCheck, setNewPasswordCheck] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [msg, setMsg] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    userData.user && setName(userData.user.name);
  }, [userData.user]);

  useEffect(() => {
    let changeUserCard = document.getElementById('change-user-card');
    if (changeUserCard !== null) {
      if (rolledOutMode === 'changeUser') {
        changeUserCard.classList.add('rolled-out');
      } else {
        changeUserCard.classList.remove('rolled-out');
      }
    }
  }, [rolledOutMode]);

  const sendUserDataToDb = async () => {
    try {
      let myRes = await UtteranceServices.changeUserData({
        password: password,
        newPassword: newPassword,
        newPasswordCheck: newPasswordCheck,
        name: name,
        role: userData.user.role,
        token: userData.token,
      });
      if (myRes.status === 200) {
        setUserData({
          token: userData.token,
          user: { name: myRes.data.user.name, role: myRes.data.user.role },
        });

        setShowSuccess(true);
        setMsg('Credentials changed');
        setTimeout(() => {
          setShowSuccess(false);
          setMsg(undefined);
          setRolledOutMode(false);
          setPassword('');
          // setName('');
          setNewPassword('');
          setNewPasswordCheck('');
        }, 3000);
      }
    } catch (error) {
      setShowAlert(true);
      error.response.data.msg && setMsg(error.response.data.msg);
      setTimeout(() => {
        setShowAlert(false);
        setMsg(undefined);
      }, 3000);
      console.log(error);
    }
  };

  const changeUserDataJSX = (
    <fieldset>
      <div className='form-group row px-3 my-1'>
        <label htmlFor='userName' className='mb-0'>
          <small>Username</small>
        </label>
        <input
          type='text'
          className='form-control form-control-sm'
          id='userName'
          name='userName'
          autoCorrect='off'
          autoCapitalize='off'
          autoComplete='off'
          spellCheck='false'
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
          required
        />
      </div>

      <div className='form-group row px-3 mb-2'>
        <label htmlFor='oldPpassword' className='mb-0'>
          <small>Password</small>
        </label>
        <input
          type='password'
          className='form-control form-control-sm'
          id='oldPassword'
          name='password'
          value={password}
          onChange={e => setPassword(e.target.value)}
          required
        />
      </div>
      <div className='form-group row px-3 mb-2'>
        <label htmlFor='newPassword' className='mb-0'>
          <small>New Password</small>
        </label>
        <input
          type='password'
          className='form-control form-control-sm'
          id='newPassword'
          name='password'
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
        />
      </div>
      <div className='form-group row px-3 mb-3'>
        <label htmlFor='newPasswordCheck' className='mb-0'>
          <small>Confirm New Password</small>
        </label>
        <input
          type='password'
          className='form-control form-control-sm'
          id='newPasswordCheck'
          name='password'
          value={newPasswordCheck}
          onChange={e => setNewPasswordCheck(e.target.value)}
        />
      </div>

      {showAlert && (
        <div className='row justify-content-center px-3 mb-2'>
          <div className='alert alert-dismissible alert-danger pr-0'>
            <button type='button' className='close p-0' data-dismiss='alert'>
              &times;
            </button>
            <div className='mr-3'>
              <strong>{msg}</strong>
            </div>
          </div>
        </div>
      )}
      {showSuccess && (
        <div className='row justify-content-center px-3 mb-2'>
          <div className='alert alert-dismissible alert-success pr-0'>
            <div className='mr-3'>
              <strong>{msg}</strong>
            </div>
          </div>
        </div>
      )}

      <div className='row justify-content-end px-3 mb-2'>
        <button
          id='changeUserDataButton'
          className='btn btn-sm btn-primary'
          onClick={sendUserDataToDb}
          type='submit'
        >
          Submit Changes
        </button>
      </div>
    </fieldset>
  );

  return (
    <div
      id='change-user-card'
      className='m-1 card login-card bg-primary change-user-card'
    >
      <div className='card-header py-0 pr-1 text-white'>
        <button
          type='button'
          className='close'
          onClick={() => setRolledOutMode(false)}
        >
          &times;
        </button>
        <div className='py-2'>Change Username and/or Password</div>
      </div>
      <div className='card-body py-0'>
        <form onSubmit={e => e.preventDefault()}>{changeUserDataJSX}</form>
      </div>
    </div>
  );
};

export default ChangeUserData;
