import React from 'react';
import ManIcon from './icons/ManIcon.js.js';
import WomanIcon from './icons/WomanIcon.js';

const GenderIcon = ({ sex, age, startTooltip, endTooltip }) => {
  let className = 'fas fa-lg ';
  let genderText = '';
  let genderDisplayText = '';
  let ageText = '';

  // widthHeight in rem
  const widthHeight = {
    width: 7,
    height: 2,
  };
  if (sex === 'f') {
    className += ' fa-female female-color';
    genderText = <strong>Female speaker</strong>;
  } else if (sex === 'm') {
    className += ' fa-male male-color';
    genderText = <strong>Male speaker</strong>;
  } else {
    className += ' fa-male transparent-color';
    genderText = <strong>Gender unknown</strong>;
  }
  if (age) {
    ageText = (
      <>
        <br />
        Age: <strong>{age}</strong>
      </>
    );
  }

  genderDisplayText = (
    <>
      {genderText}
      {ageText}
    </>
  );

  return (
    <span
      className='text-center gender-icon obc-tag'
      onPointerEnter={e => startTooltip(genderDisplayText, e, widthHeight)}
      onPointerLeave={e => endTooltip(e)}
    >
      {sex === 'f' ? (
        <WomanIcon height='20px' className='female-color' />
      ) : (
        <ManIcon height='20px' className='male-color' />
      )}
      {/* <i className={className}></i> */}
    </span>
  );
};

export default GenderIcon;
