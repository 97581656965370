import React, { useState, useContext, useEffect } from 'react';
import UtteranceServices from '../services/UtteranceServices';
import UserContext from '../context/UserContext';
import AlertCard from './AlertCard';

const UserListElement = ({ user, index, reloadUsers }) => {
  const [changedUser, setChangedUser] = useState(user);
  const [showSuccess, setShowSuccess] = useState(false);
  // const [showError, setShowError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [msg, setMsg] = useState(undefined);
  const { userData } = useContext(UserContext);

  useEffect(() => {
    setChangedUser(user);
  }, [user]);

  const changeName = e => {
    setChangedUser({ ...changedUser, name: e.target.value });
  };

  const changeEmail = e => {
    setChangedUser({ ...changedUser, email: e.target.value });
  };

  const changeRole = e => {
    setChangedUser({ ...changedUser, role: e.target.value });
  };

  const deleteUser = e => {
    setShowDeleteConfirmation(true);
  };

  const finallyDeleteUser = async e => {
    e.currentTarget.blur();
    setShowDeleteConfirmation(false);
    try {
      let myRes = await UtteranceServices.deleteUser({
        id: changedUser.id,
        adminToken: userData.token,
      });
      if (myRes.status === 200) {
        setShowSuccess(true);
        setMsg(myRes.data.msg);
        setTimeout(() => {
          setShowSuccess(false);
          setMsg(undefined);
          reloadUsers();
        }, 3000);
      }
    } catch (error) {
      setShowAlert(true);
      error.response.data.msg && setMsg(error.response.data.msg);
      setTimeout(() => {
        setShowAlert(false);
        setMsg(undefined);
      }, 3000);
      console.log(error);
    }
  };

  const resetPassword = async e => {
    e.currentTarget.blur();

    let myParams = {
      name: changedUser.name,
      email: changedUser.email,
      token: userData.token,
    };
    // let myToken;
    // if (user.role === 'Administrator') {
    //   myToken = userData.token;
    // } else {
    //   myToken =
    // }
    try {
      let myRes = await UtteranceServices.resetPassword(myParams);
      if (myRes.status === 200) {
        setShowSuccess(true);
        setMsg(myRes.data.msg);
        setTimeout(() => {
          setShowSuccess(false);
          setMsg(undefined);
          reloadUsers();
        }, 3000);
      }
    } catch (error) {
      setShowAlert(true);
      error.response.data.msg && setMsg(error.response.data.msg);
      setTimeout(() => {
        setShowAlert(false);
        setMsg(undefined);
      }, 3000);
      console.log(error);
    }
  };

  const saveChanges = async e => {
    e.currentTarget.blur();

    try {
      let myRes = await UtteranceServices.changeUserDataByAdmin({
        name: changedUser.name,
        role: changedUser.role,
        email: changedUser.email,
        id: changedUser.id,
        adminToken: userData.token,
      });
      if (myRes.status === 200) {
        setShowSuccess(true);
        setMsg(myRes.data.msg);
        setTimeout(() => {
          setShowSuccess(false);
          setMsg(undefined);
        }, 3000);
      }
    } catch (error) {
      setShowAlert(true);
      error.response.data.msg && setMsg(error.response.data.msg);
      setTimeout(() => {
        setShowAlert(false);
        setMsg(undefined);
      }, 3000);
      console.log(error);
    }
  };

  return (
    <div
      className={
        user.role === 'Administrator'
          ? 'card mb-3 border-danger'
          : 'card mb-3 border-success'
      }
    >
      <div className='card-header text-left'>{`${index}. ${user.name}`}</div>
      <div className='card-body text-left'>
        <div className='row justify-content-center'>
          {showDeleteConfirmation && (
            <div className='alert alert-dismissible alert-danger p-3 text-center alert-card'>
              <strong>Delete User {user.name}?</strong>
              <div className='row justify-content-between mt-3 mr-3 mb-0'>
                <div
                  className='btn btn-primary mx-3'
                  onClick={() => setShowDeleteConfirmation(false)}
                >
                  <i className='far fa-times-circle'></i> Cancel
                </div>
                <div className='btn btn-primary' onClick={finallyDeleteUser}>
                  <i className='fas fa-check'></i> Delete
                </div>
              </div>
            </div>
          )}
          {showAlert && <AlertCard type='danger' msg={msg} />}
          {showSuccess && <AlertCard type='success' msg={msg} />}
        </div>
        <div className='row d-flex flex-nowrap'>
          <div className='col'>
            <label htmlFor='inputUserName' className='mb-0'>
              User Name
            </label>
            <input
              id='inputUserName'
              className='form-control form-control-sm mt-0 mb-3'
              type='text'
              value={changedUser.name}
              onChange={changeName}
              autoComplete='off'
              spellCheck='false'
            />
          </div>
          <div className='col'>
            <div className='form-group'>
              <label htmlFor='selectUserRole' className='form-label-sm mb-0'>
                Role
              </label>
              <select
                className='form-control form-control-sm'
                id='selectUserRole'
                value={changedUser.role}
                onChange={changeRole}
              >
                <option>Administrator</option>
                <option>Data Handler</option>
              </select>
            </div>
          </div>
        </div>

        <label htmlFor='inputEmail' className='mb-0'>
          Email
        </label>
        <input
          id='inputEmail'
          className='form-control form-control-sm mt-0 mb-3'
          type='text'
          value={changedUser.email}
          onChange={changeEmail}
          autoComplete='off'
          spellCheck='false'
        />

        <div className='d-flex justify-content-between align-items-end'>
          <span>
            <span
              id='deleteUserButton'
              className='pointer-cursor link mr-3'
              onClick={deleteUser}
            >
              <small>Delete User</small>
            </span>
            {/* <button className='btn btn-primary' onClick={deleteUser}>
            <i className='far fa-trash-alt pointer-cursor mr-2'></i>
            Delete User
          </button> */}
            <span
              id='forgotPasswordButton'
              className='pointer-cursor link'
              onClick={resetPassword}
            >
              <small>Send new Password</small>
            </span>
            {/* <button className='btn btn-primary' onClick={resetPassword}>
            <i className='far fa-envelope pointer-cursor mr-2'></i>
            Mail new Password
          </button> */}
          </span>

          <button className='btn btn-primary' onClick={saveChanges}>
            <i className='far fa-save pointer-cursor mr-2'></i>Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserListElement;
