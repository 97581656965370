import UtteranceService from './UtteranceServices';

const getTotalHits = async searchParams => {
  let result = 0;
  const getHits = async () => {
    const res = await UtteranceService.getNoOfHits(searchParams);
    const { data } = await res;
    result = data;
  };

  await getHits();
  return result;
};

export default getTotalHits;
