import React from 'react';

const AlertCard = ({ type, msg }) => {
  let myClassList = 'alert alert-dismissible pr-0 text-center alert-card ';

  switch (type) {
    case 'success':
      myClassList += 'alert-success';
      break;
    case 'warning':
      myClassList += 'alert-danger';
      break;
    default:
  }
  return (
    <div className={myClassList}>
      <strong>{msg}</strong>
    </div>
  );
};

export default AlertCard;
