import React, { useState, useContext, useEffect } from 'react';
import UserContext from '../context/UserContext';
import UtteranceServices from '../services/UtteranceServices';

const LoginOutCard = ({ rolledOutMode, setRolledOutMode }) => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [msg, setMsg] = useState('');

  const { userData, setUserData } = useContext(UserContext);

  useEffect(() => {
    let loginCard = document.getElementById('login-card');
    if (loginCard !== null) {
      if (rolledOutMode === 'login') {
        loginCard.classList.add('rolled-out');
      } else {
        loginCard.classList.remove('rolled-out');
      }
    }
  }, [rolledOutMode]);

  const loginUser = async () => {
    try {
      let myRes = await UtteranceServices.login({
        password: password,
        name: name,
      });

      if (myRes.status === 200) {
        setUserData({
          token: myRes.data.token,
          user: {
            name: myRes.data.user.name,
            role: myRes.data.user.role,
            id: myRes.data.user.id,
          },
        });

        setMsg(myRes.data.msg);
        setTimeout(() => {
          setMsg(undefined);
          setRolledOutMode(false);
        }, 3000);

        localStorage.setItem('auth-token', myRes.data.token);

        setPassword('');
        setName('');
      }
    } catch (error) {
      setShowAlert(true);
      error.response.data.msg && setMsg(error.response.data.msg);
      setTimeout(() => {
        setShowAlert(false);
        setMsg(undefined);
      }, 3000);
      console.log(error);
    }
  };

  const logOut = e => {
    if (e.currentTarget.id === 'logOutButton') {
      setRolledOutMode(false);
      localStorage.setItem('auth-token', '');

      setTimeout(
        () =>
          setUserData({
            token: undefined,
            user: undefined,
          }),
        1000
      );
    }
  };

  const changeUserData = e => {
    setRolledOutMode('changeUser');

    // setRolledOutChangeUserData(true);
  };

  const forgotPassword = e => {
    setRolledOutMode('forgotPassword');
  };

  const logOutJSX = (
    <fieldset>
      <div className='form-group row px-3 mt-3 my-2'>
        <h5 className='mx-auto'>{userData.user && userData.user.name}</h5>
      </div>
      <div className='row justify-content-between px-3 mb-1'>
        <span
          id='changeUserDataButton'
          className='pointer-cursor link'
          onClick={changeUserData}
        >
          <small>Change Data</small>
        </span>
        <span
          id='logOutButton'
          className='pointer-cursor link'
          onClick={logOut}
        >
          <small>Logout</small>
        </span>
      </div>
    </fieldset>
  );

  const logInJSX = (
    <fieldset>
      <div className='form-group row px-3 my-1'>
        <label htmlFor='userName' className='mb-0'>
          <small>Username</small>
        </label>
        <input
          type='text'
          className='form-control form-control-sm'
          id='userName'
          name='userName'
          autoCorrect='off'
          autoCapitalize='off'
          autoComplete='off'
          spellCheck='false'
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
        />
      </div>

      <div className='form-group row px-3 mb-3'>
        <label htmlFor='password' className='mb-0'>
          <small>Password</small>
        </label>
        <input
          type='password'
          className='form-control form-control-sm'
          id='password'
          name='password'
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </div>

      {showAlert && (
        <div className='row justify-content-center px-3 mb-2'>
          <div className='alert alert-dismissible alert-danger pr-0'>
            <div className='mr-3'>
              <strong>{msg}</strong>
            </div>
          </div>
        </div>
      )}

      <div className='row justify-content-between px-3 mb-2'>
        <span
          id='forgotPasswordButton'
          className='pointer-cursor link'
          onClick={forgotPassword}
        >
          <small>Forgot Password</small>
        </span>
        <button
          id='loginButton'
          className='btn btn-sm btn-primary'
          onClick={loginUser}
          type='submit'
        >
          Login
        </button>
      </div>
    </fieldset>
  );

  return (
    <div>
      <div id='login-card' className='m-1 card login-card bg-primary'>
        <div className='card-header'>
          {userData.user ? 'Logged in as' : 'Login'}
          <button
            type='button'
            className='close'
            onClick={() => setRolledOutMode(false)}
          >
            &times;
          </button>
        </div>
        <div className='card-body py-0 pr-1'>
          <form onSubmit={e => e.preventDefault()}>
            {userData.user ? logOutJSX : logInJSX}
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginOutCard;
