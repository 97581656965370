import React, { useEffect, useState } from 'react';

const ToolTip = ({ tooltip }) => {
  // Depending on the value of tooltip.show
  // the opacity of the tooltip element is set

  const [opacity, setOpacity] = useState('0');

  useEffect(() => {
    if (tooltip.show === true) {
      setOpacity('1');
    } else {
      setOpacity('0');
    }
  }, [tooltip.show]);

  return (
    <>
      <div
        id='obc-tooltip'
        className='obc-tooltip'
        style={{
          left: `${tooltip.x}px`,
          top: `${tooltip.y}px`,
          opacity: `${opacity}`,
          width: `${tooltip.width}px`,
          height: `${tooltip.height}px`,
        }}
      >
        {tooltip.jsx}
      </div>
    </>
  );
};

export default ToolTip;
