import React from 'react';
import Scribe from './icons/Scribe';

const ScribePrinter = ({ printtags, startTooltip, endTooltip }) => {
  if (printtags === undefined) {
    return null;
  }
  // widthHeight in rem
  const widthHeight = {
    width: 20,
    height: 6.5,
  };
  const { scribe, printer, editor, publisher } = printtags;

  const scribePrinterText = (
    <div>
      <div className='row'>
        <div className='col-4'>Scribe: </div>
        <div className='col-8'>
          <strong>{scribe}</strong>
        </div>
      </div>
      <div className='row'>
        <div className='col-4'>Printer: </div>
        <div className='col-8'>
          <strong>{printer}</strong>
        </div>
      </div>
      <div className='row'>
        <div className='col-4'>Editor: </div>
        <div className='col-8'>
          <strong>{editor}</strong>
        </div>
      </div>
      <div className='row'>
        <div className='col-4'>Publisher: </div>
        <div className='col-8'>
          <strong>{publisher}</strong>
        </div>
      </div>
    </div>
  );

  return (
    <span
      className='badge'
      // className={`badge badge-pill badge-light obc-tag`}
      onPointerEnter={e => startTooltip(scribePrinterText, e, widthHeight)}
      onPointerLeave={e => endTooltip(e)}
    >
      <Scribe className='scribe' width='15px' />
      {/* <i className='far fa-file-alt text-primary'></i> */}
    </span>
  );
};

export default ScribePrinter;
