import React, { useEffect, useLayoutEffect, useState } from 'react';
import HiscoLabel from './HiscoLabel';
import OldBaileyLink from './OldBaileyLink';
import ScribePrinter from './ScribePrinter';
import GenderIcon from './Gender';
import SocialClass from './SocialClass';
import Year from './Year';
import Role from './Role';
import Offence from './Offence';
import { createLongJSX, createShortJSX } from '../includes/textTools';
import {
  // BREAKPOINT_LG,
  BREAKPOINT_MD,
  // BREAKPOINT_SM,
} from '../includes/constants';
import ExpandedText from './ExpandedText';

// import Tooltip from 'react-tooltip-lite';

const TableRow = ({
  row: {
    utterance,
    activities,
    hiscoTags,
    printTags,
    periodTags,
    offenceTags,
    year,
    url,
    sex,
    age,
    role,
    hit,
  },
  index,
  startTooltip,
  endTooltip,
  screenWidth,
  tagsExpandedByUser,
  setTagsExpandedByUser,
}) => {
  const [showExpandedText, setShowExpandedText] = useState(true);
  const [longJSX, setLongJSX] = useState('');
  const [shortJSX, setShortJSX] = useState('');
  // const [yPos, setYPos] = useState(0);
  // const [expandedTextHeight, setExpandedTextHeight] = useState(0);

  // widthHeight in rem
  const widthHeight = {
    width: 14,
    height: 2,
  };

  useEffect(() => {
    setLongJSX(createLongJSX(utterance, activities, hit));
    setShortJSX(createShortJSX(hit, index, activities, utterance));
  }, [utterance]);

  // if utterance is changed, show shortText
  useEffect(() => {
    setShowExpandedText(false);
    hideExpandedText();
  }, [utterance]);

  // useLayoutEffect(() => {
  //   let myExpandedText = document.getElementById(
  //     `row-obc-result-expanded-${index}`
  //   );
  //   console.log(myExpandedText);
  //   setExpandedTextHeight(myExpandedText.scrollHeight);
  //   // console.log(myExpandedText.scrollHeight);
  //   myExpandedText.style.maxHeight = '0px';
  // }, [index]);

  // collpase to short text, if tags are expanded
  useEffect(() => {
    if (tagsExpandedByUser) {
      setShowExpandedText(false);
      hideExpandedText();
    }
  }, [tagsExpandedByUser]);

  // striping
  let bgColor = 'bg-dark';
  if (index % 2 === 0) {
    bgColor = 'bg-primary';
  }

  const hideExpandedText = () => {
    let myExpandedText = document.getElementById(
      `row-obc-result-expanded-${index}`
    );
    myExpandedText.style.maxHeight = '0px';
    myExpandedText.style.padding = '0rem 0.5rem';
    myExpandedText.style.borderTop = '1px solid rgba(122,130,136,0)';
    myExpandedText.style.marginBottom = '0px';
  };

  // toggle if shortText or longText is shown
  const handleOnClick = () => {
    let myExpandedText = document.getElementById(
      `row-obc-result-expanded-${index}`
    );
    if (!showExpandedText) {
      myExpandedText.style.maxHeight = '300px';
      myExpandedText.style.padding = '0.25rem 1rem';
      myExpandedText.style.borderTop = '1px solid rgba(122,130,136,1)';
      myExpandedText.style.marginBottom = '4px';
    } else {
      hideExpandedText();
    }
    setShowExpandedText(!showExpandedText);
    setTagsExpandedByUser(false);
  };

  const ttJSXText = <>Click to toggle display of full text</>;

  return (
    <div
      id={`row-obc-result-${index}`}
      className='row justify-content-between flex-nowrap overflow obc-row'
    >
      <div className={`${bgColor} col-number text-monospace text-right`}>
        {index}
      </div>

      <div
        className={`col d-block text-monospace part-content ${bgColor}`}
        onClick={e => handleOnClick(e)}
        onPointerEnter={e => startTooltip(ttJSXText, e, widthHeight, 'right')}
        onPointerLeave={e => endTooltip(e)}
      >
        {shortJSX}
        <ExpandedText
          longJSX={longJSX}
          handleOnClick={handleOnClick}
          // yPos={yPos}
          id={`row-obc-result-expanded-${index}`}
        />
      </div>

      {/* {showLongText ? (
        <div
          className={`col d-block text-monospace text-left ${bgColor} overflow`}
          onClick={handleOnClick}
          onPointerEnter={e => startTooltip(ttJSXText, e, widthHeight, 'right')}
          onPointerLeave={e => endTooltip(e)}
        >
          <span className={'overflow'}>{longJSX}</span>
        </div>
      ) : (
        <div
          className={`col d-block text-monospace part-content ${bgColor}`}
          onClick={handleOnClick}
          onPointerEnter={e => startTooltip(ttJSXText, e, widthHeight, 'right')}
          onPointerLeave={e => endTooltip(e)}
        >
          {shortJSX}
        </div>
      )} */}

      <div
        className={`col text-left ${bgColor} tag-col ${
          screenWidth < BREAKPOINT_MD ? 'collapsed' : ''
        }`}
      >
        <div className={`row tag-row`}>
          <div className='url-col gen-tag-col'>
            <OldBaileyLink
              url={url}
              startTooltip={startTooltip}
              endTooltip={endTooltip}
            />
          </div>

          <div className='year-col gen-tag-col'>
            <Year
              year={year}
              periods={periodTags}
              index={index}
              startTooltip={startTooltip}
              endTooltip={endTooltip}
            />
          </div>

          <div className='gender-col gen-tag-col'>
            <GenderIcon
              sex={sex}
              age={age}
              index={index}
              startTooltip={startTooltip}
              endTooltip={endTooltip}
            />
          </div>

          <div className='hisco-col gen-tag-col'>
            {hiscoTags !== undefined && (
              <HiscoLabel
                hiscoLabel={hiscoTags.hiscoLabel}
                hiscoCode={hiscoTags.hiscoCode}
                index={index}
                startTooltip={startTooltip}
                endTooltip={endTooltip}
              />
            )}
          </div>

          <div className='class-col gen-tag-col'>
            {hiscoTags !== undefined && (
              <SocialClass
                hisclass={hiscoTags.hisclass}
                index={index}
                startTooltip={startTooltip}
                endTooltip={endTooltip}
              />
            )}
          </div>

          <div className='role-col gen-tag-col'>
            {role !== undefined && (
              <Role
                role={role}
                index={index}
                startTooltip={startTooltip}
                endTooltip={endTooltip}
              />
            )}
          </div>

          <div className='offence-col gen-tag-col'>
            <Offence
              offenceTags={offenceTags}
              index={index}
              startTooltip={startTooltip}
              endTooltip={endTooltip}
            />
          </div>

          <div className='scribe-col gen-tag-col'>
            <ScribePrinter
              printtags={printTags}
              index={index}
              startTooltip={startTooltip}
              endTooltip={endTooltip}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableRow;
