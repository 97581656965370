// This file is not tracked remotely.
// To start tracking again use
// git update-index --no-skip-worktree ./src/includes/constants.js

// remote strato
export const BASE_URL = 'https://backend.obc-client.de/api';
export const PUBLIC_URL = 'https://www.obc-client.de/files/';
// export const WS_URL = 'https://obc-26727e4d1b74.herokuapp.com';

// remote
// export const BASE_URL = 'https://obc-26727e4d1b74.herokuapp.com/api';
// export const WS_URL = 'https://obc-26727e4d1b74.herokuapp.com';

// local
// export const BASE_URL = 'http://localhost:5000/api';
// export const WS_URL = null;

export const PAGE_SIZE = 50;

export const MIN_YEAR = 1720;
export const MAX_YEAR = 1913;

export const BREAKPOINT_LG = 1280;
export const BREAKPOINT_MD = 960;
export const BREAKPOINT_SM = 576;

export const OBC_VERSION = '3.0';
export const APP_VERSION = '1.0.0';

export const URL_REF = 'https://www.oldbaileyonline.org/browse.jsp?div=';

export const URL_HISCO =
  'https://historyofwork.iisg.nl/list_micro.php?keywords=';

export const URL_HISCLASS = 'https://hdl.handle.net/10622/HEFSW2';
