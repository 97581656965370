import { URL_REF } from './constants';

const buildJSON = (xmlDoc, fileName) => {
  const uJSONArray = [];
  const uTags = xmlDoc.getElementsByTagName('u');
  for (let i = 0; i < uTags.length; i++) {
    // let uTag = cleanUpUTag(uTags[i]);
    const json = jsonFromUTag(uTags[i], i, fileName);

    uJSONArray.push(json);
  }
  return uJSONArray;
};

const jsonFromUTag = (uTag, counter, fileName) => {
  const atts = uTag.attributes;
  let utterance = '';
  let utterance_id = 0;
  let acts = [];
  const childNodes = uTag.childNodes;
  for (let i = 0; i < childNodes.length; i++) {
    const child = childNodes[i];
    if (child.nodeType === Node.TEXT_NODE) {
      utterance += child.nodeValue.trim() + ' ';
    } else if (child.nodeName === 'activity') {
      acts.push({
        activity: child.childNodes[0].nodeValue,
        position: utterance.length,
      });
    }
  }

  let json = {};
  let hiscoTags = {};
  let printTags = {};
  let periodTags = {};
  let offenceTags = {};
  for (let i = 0; i < atts.length; i++) {
    if (atts[i].nodeValue !== '') {
      switch (atts[i].nodeName) {
        case 'p2':
        case 'p3':
        case 'p4':
        case 'p5':
        case 'p6': {
          periodTags[atts[i].nodeName] = atts[i].nodeValue;
          json.periodTags = periodTags;
          break;
        }
        case 'offCat':
        case 'offSub':
        case 'verCat':
        case 'verSub':
        case 'punCat':
        case 'punSub': {
          offenceTags[atts[i].nodeName] = atts[i].nodeValue;
          json.offenceTags = offenceTags;
          break;
        }
        case 'hiscoCode': {
          hiscoTags['hiscoCode'] = atts[i].nodeValue;
          json.hiscoTags = hiscoTags;
          break;
        }
        case 'hiscoLabel': {
          hiscoTags['hiscoLabel'] = atts[i].nodeValue;
          json.hiscoTags = hiscoTags;
          break;
        }
        case 'hisclass': {
          hiscoTags['hisclass'] = atts[i].nodeValue;
          json.hiscoTags = hiscoTags;
          break;
        }
        case 'scribe': {
          printTags['scribe'] = atts[i].nodeValue;
          json.printTags = printTags;
          break;
        }
        case 'editor': {
          printTags['editor'] = atts[i].nodeValue;
          json.printTags = printTags;
          break;
        }
        case 'printer': {
          printTags['printer'] = atts[i].nodeValue;
          json.printTags = printTags;
          break;
        }
        case 'publisher': {
          printTags['publisher'] = atts[i].nodeValue;
          json.printTags = printTags;
          break;
        }
        case 'trial': {
          let myTrialIDs = atts[i].nodeValue.split('-');

          let mySecondPart = myTrialIDs[1].replace(/[^0-9]/g, '');

          utterance_id =
            myTrialIDs[0].substr(1) +
            mySecondPart.padStart(4, '0') +
            counter.toString().padStart(4, '0');
          json.utterance_id = utterance_id;
          json[atts[i].nodeName] = atts[i].nodeValue;
          break;
        }
        default: {
          json[atts[i].nodeName] = atts[i].nodeValue;
        }
        // console.log(json);
      }
    }
  }
  json.url = `${URL_REF}${uTag.getAttribute('trial')}`;
  json.utterance = utterance.trim();
  json.fileName = fileName;

  if (acts.length > 0) {
    json['activities'] = acts;
  }

  return json;
};

export default buildJSON;
