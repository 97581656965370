import React from 'react';

const Datenschutz = () => {
  return (
    <div className='col mx-auto'>
      <div className='mb-3 pb-3'>
        <div className='card outmost-card text-white bg-secondary mt-3 mb-4 mx-auto'>
          <div className='card-header'>Datenschutz</div>
          <div className='card-body text-left pb-0'>
            <p>Verantwortlich für die Datenverarbeitung ist</p>
            <h5 className='mb-0'>BlickSolutions GmbH</h5>z.H. Calvin Blick
            <h5 className='mt-3 mb-0'>Sitz der Gesellschaft</h5>
            Freiherr-vom-Stein-Str. 5065520 Bad Camberg
            <h5 className='mt-3 mb-0'>E-Mail</h5>
            <a href='mailto:hallo@blicksolutions.de'>hallo@blicksolutions.de</a>
            <h5 className='mt-3 mb-0'>Telefon</h5>
            <p>+49 177 6261990</p>
            <h5 className='mt-3 mb-0'>Zugriffsdaten und Hosting</h5>
            <p>
              Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person
              zu machen. Bei jedem Aufruf einer Webseite speichert der Webserver
              lediglich automatisch ein sogenanntes Server-Logfile, das z.B. den
              Namen der angeforderten Datei, Ihre IP-Adresse, Datum und Uhrzeit
              des Abrufs, übertragene Datenmenge und den anfragenden Provider
              (Zugriffsdaten) enthält und den Abruf dokumentiert. Diese
              Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung
              eines störungsfreien Betriebs der Seite sowie der Verbesserung
              unseres Angebots ausgewertet. Dies dient gemäß Art. 6 Abs. 1 S. 1
              lit. f DSGVO der Wahrung unserer im Rahmen einer
              Interessensabwägung überwiegenden berechtigten Interessen an einer
              korrekten Darstellung unseres Angebots. Alle Zugriffsdaten werden
              spätestens sieben Tage nach Ende Ihres Seitenbesuchs gelöscht.
            </p>
            <h5 className='mt-3 mb-0'>
              Hostingdienstleistungen durch einen Drittanbieter
            </h5>
            <p>
              Im Rahmen einer Verarbeitung in unserem Auftrag erbringt ein
              Drittanbieter für uns die Dienste zum Hosting und zur Darstellung
              der Webseite. Alle Daten, die im Rahmen der Nutzung dieser
              Webseite wie folgend beschrieben erhoben werden, werden auf seinen
              Servern verarbeitet. Eine Verarbeitung auf anderen Servern findet
              nur in dem hier erläuterten Rahmen statt. Dieser Dienstleister
              sitzt innerhalb eines Landes der Europäischen Union oder des
              Europäischen Wirtschaftsraums.
            </p>
            <h5 className='mt-3 mb-0'>Datenerhebung und Verwendung</h5>
            <p>
              Wir erheben personenbezogene Daten, wenn Sie uns diese im Rahmen
              einer Kontaktaufnahme mit uns (z.B. per Kontaktformular oder
              E-Mail) freiwillig mitteilen. Pflichtfelder werden als solche
              gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur
              Vertragsabwicklung, bzw. zur Bearbeitung Ihrer Kontaktaufnahme
              benötigen und Sie ohne deren Angabe die Kontaktaufnahme nicht
              versenden können. Welche Daten erhoben werden, ist aus den
              jeweiligen Eingabeformularen ersichtlich. Wir verwenden die von
              ihnen mitgeteilten Daten gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO zur
              Bearbeitung Ihrer Anfragen. Soweit Sie hierzu Ihre Einwilligung
              nach Art. 6 Abs. 1 S. 1 lit. a DSGVO erteilt haben, indem Sie sich
              für die Eröffnung eines Kundenkontos entscheiden, verwenden wir
              Ihre Daten zum Zwecke der Kundenkontoeröffnung. Nach Löschung
              Ihres Kundenkontos werden Ihre Daten für die weitere Verarbeitung
              eingeschränkt und nach Ablauf der steuer- und handelsrechtlichen
              Aufbewahrungsfristen gelöscht, sofern Sie nicht ausdrücklich in
              eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns
              eine darüber hinausgehende Datenverwendung vorbehalten, die
              gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung
              informieren. Die Löschung Ihres Kundenkontos ist jederzeit möglich
              und kann durch eine Nachricht an die unten beschriebene
              Kontaktmöglichkeit erfolgen.
            </p>
            <h5 className='mt-3 mb-0'>Kontaktmöglichkeiten und Ihre Rechte</h5>
            <p>
              * gemäß Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang
              Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten
              zu verlangen; * gemäß Art. 16 DSGVO das Recht, unverzüglich die
              Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns
              gespeicherten personenbezogenen Daten zu verlangen; * gemäß Art.
              17 DSGVO das Recht, die Löschung Ihrer bei uns gespeicherten
              personenbezogenen Daten zu verlangen, soweit nicht die weitere
              Verarbeitung * zur Ausübung des Rechts auf freie Meinungsäußerung
              und Information; * zur Erfüllung einer rechtlichen Verpflichtung;
              * aus Gründen des öffentlichen Interesses oder * zur
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
              erforderlich ist; * gemäß Art. 18 DSGVO das Recht, die
              Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
              verlangen, soweit * die Richtigkeit der Daten von Ihnen bestritten
              wird; * die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung
              ablehnen; * wir die Daten nicht mehr benötigen, Sie diese jedoch
              zur Geltendmachung, Ausübung oder Verteidigung von
              Rechtsansprüchen benötigen oder * Sie gemäß Art. 21 DSGVO
              Widerspruch gegen die Verarbeitung eingelegt haben; * gemäß Art.
              20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie uns
              bereitgestellt haben, in einem strukturierten, gängigen und
              maschinenlesebaren Format zu erhalten oder die Übermittlung an
              einen anderen Verantwortlichen zu verlangen; * gemäß Art. 77 DSGVO
              das Recht, sich bei einer Aufsichtsbehörde zu beschweren. In der
              Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres
              üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres
              Unternehmenssitzes wenden. Bei Fragen zur Erhebung, Verarbeitung
              oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften,
              Berichtigung, Einschränkung oder Löschung von Daten sowie Widerruf
              erteilter Einwilligungen oder Widerspruch gegen eine bestimmte
              Datenverwendung wenden Sie sich bitte direkt an uns über die
              Kontaktdaten in unserem Impressum.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Datenschutz;
