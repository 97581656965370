const ArrowUpIcon = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' {...props}>
      <polygon
        points='50 3 3 50 25 50 25 97 50 97 75 97 75 50 97 50 50 3'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='5'
      />
    </svg>
  );
};

export default ArrowUpIcon;
