import React from 'react';
import LinkIcon from './icons/LinkIcon';

const OldBaileyLink = ({ url, startTooltip, endTooltip }) => {
  // widthHeight in rem
  const widthHeight = {
    width: 18,
    height: 2,
  };
  const oldBaileyText = (
    <div>
      <a
        target='_blank'
        rel='noopener noreferrer'
        data-tip
        data-for='link-tip'
        href={url}
      >
        Click to see whole trial on oldbaileyonline.org
      </a>
    </div>
  );

  return (
    <a
      target='_blank'
      rel='noopener noreferrer'
      data-tip
      data-for='link-tip'
      href={url}
      onPointerEnter={e => startTooltip(oldBaileyText, e, widthHeight)}
      onPointerLeave={e => endTooltip(e)}
    >
      <LinkIcon width='14px' className='obc-link' />
      {/* <i className='fas fa-external-link-alt fa-sm text-light'></i> */}
    </a>
  );
};

export default OldBaileyLink;
