import http from '../http-common';
// import fs from 'fs';

const deleteTrial = data => {
  return http.post(`${http.defaults.baseUrl}/deleteTrial`, data);
};

const create = data => {
  return http.post(`${http.defaults.baseUrl}/`, data);
};

const createMany = data => {
  return http.post(`${http.defaults.baseUrl}/createMany`, data);
};

const getNoOfHits = params => {
  const myParams = new URLSearchParams(params);
  return http
    .get(`${http.defaults.baseUrl}/countAllHits?${myParams}`)
    .catch(err => {
      console.log(err);
    });
};

// const getPage = params => {
//   const myParams = new URLSearchParams(params);
//   return http.get(`${http.defaults.baseUrl}/page?${myParams}`);
// };

const getPageNew = params => {
  const myParams = new URLSearchParams(params);
  return http.get(`${http.defaults.baseUrl}/pageNew?${myParams}`).catch(err => {
    console.log(err);
  });
};

const downloadXLS = params => {
  const myParams = new URLSearchParams(params);
  return http.get(`${http.defaults.baseUrl}/xls?${myParams}`);
};

// const download = async () => {
//   let myRes = await http({
//     method: 'get',
//     responseType: 'blob',
//     // responseType:
//     //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//     url: `${http.defaults.baseUrl}/download`,
//     // headers: {
//     //   'Content-Disposition': 'attachment; filename=template.xlsx',
//     //   'Content-Type':
//     //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//     // },
//   });

//   // return myRes;
//   const url = window.URL.createObjectURL(new Blob([myRes.data]));
//   const link = document.createElement('a');
//   link.href = url;
//   // const contentDisposition = myRes.headers['content-disposition'];
//   // console.log(contentDisposition);
//   let fileName = 'unknown';
//   // if (contentDisposition) {
//   //   const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
//   //   if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
//   // }
//   link.setAttribute('download', 'frequency.xlsx');
//   document.body.appendChild(link);
//   link.click();
//   window.URL.revokeObjectURL(url);
//   link.remove();

//   // return myRes;
// };

const login = data => {
  return http.post(`${http.defaults.baseUrl}/login`, data);
};

const tokenIsValid = token => {
  http.defaults.headers.post['x-auth-token'] = token;
  return http.post(`${http.defaults.baseUrl}/tokenIsValid`);
};

const getUser = token => {
  http.defaults.headers.get['x-auth-token'] = token;
  return http.get(`${http.defaults.baseUrl}/getUser`);
};

const getAllUsers = () => {
  return http.get(`${http.defaults.baseUrl}/getAllUsers`);
};

const changeUserData = data => {
  return http.post(`${http.defaults.baseUrl}/changeUserData`, data);
};

const changeUserDataByAdmin = data => {
  return http.post(`${http.defaults.baseUrl}/changeUserDataByAdmin`, data);
};

const deleteUser = data => {
  return http.post(`${http.defaults.baseUrl}/deleteUser`, data);
};

const resetPassword = data => {
  return http.post(`${http.defaults.baseUrl}/resetPassword`, data);
};

const createUser = data => {
  return http.post(`${http.defaults.baseUrl}/createUser`, data);
};

export default {
  getNoOfHits,
  // getPage,
  getPageNew,
  create,
  createMany,
  downloadXLS,
  // replaceMany,
  login,
  tokenIsValid,
  getUser,
  getAllUsers,
  deleteTrial,
  changeUserData,
  changeUserDataByAdmin,
  deleteUser,
  resetPassword,
  createUser,
  // download,
};
