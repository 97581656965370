const DownloadIcon = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 77 79'
      {...props}
      fill='currentColor'
    >
      <path
        d='m36,2.5v55.96l-18.73-18.73c-.98-.98-2.56-.98-3.54,0-.98.98-.98,2.56,0,3.54l23,23c.12.12.25.22.38.31.06.04.12.07.18.1.08.05.16.09.25.13.08.03.17.05.25.08.07.02.14.05.22.06.32.06.66.06.98,0,.07-.01.14-.04.22-.06.08-.02.17-.04.25-.08.09-.04.16-.08.25-.13.06-.03.13-.06.18-.1.14-.09.27-.2.38-.31l23-23c.49-.49.73-1.13.73-1.77s-.24-1.28-.73-1.77c-.98-.98-2.56-.98-3.54,0l-18.73,18.73V2.5c0-1.38-1.12-2.5-2.5-2.5s-2.5,1.12-2.5,2.5Z'
        strokeWidth='0'
      />
      <path
        d='m77,76.5c0,1.38-1.12,2.5-2.5,2.5H2.5c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5h72c1.38,0,2.5,1.12,2.5,2.5Z'
        strokeWidth='0'
      />
    </svg>
  );
};

export default DownloadIcon;
